import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
  InputAdornment,
  MenuItem,
  ListItemIcon,
  Link,
  ButtonGroup,
  Tooltip,
  OutlinedInput,
  Chip,
  Drawer,
  LinearProgress,
  TextField,
  Select,
  CircularProgress,
} from "@mui/material";
import {
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Add,
  Upload,
  Download,
  GpsFixed,
  Done,
  Close,
  HistoryOutlined,
  ThumbUp,
  ThumbDown,
  MoveDownOutlined,
  MoveUpOutlined,
  DeleteOutline,
  EditOutlined,
  ReplayOutlined,
  Search,
  FilterList,
  GpsFixedRounded,
} from "@mui/icons-material";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import PromoItemsService from "../services/promoItems.service";
import { capitalizeFirstLetter } from "utils/utils";
import { SupplierStatus } from "./create.container";
import moment from "moment";
import { MyTasksAtMarketTabs, PSL, getFormStatus } from "../domains/psl.domain";
import saveAs from "file-saver";
import PromoHistory from "../components/PromoHistory.component";
import { useConfirmation } from "modules/UI/hooks/useConfirmation.hook";
import MaterialsListContainer from "./materials.container";
import ItemsCounter from "modules/UI/components/ItemsCounter.component";
import { useActiveUser } from "modules/User/activeUser";
import { toast } from "react-toastify";
import { useJustification } from "modules/UI/hooks/useJustification.hook";
import { PslFiltersContainer } from "modules/Filters/containers/PslFilters.container";
import { useFeatures } from "modules/FeatureManagement/service/featureManagement.hooks";
import { IUserScopeCompany, UserService } from "modules/User/service/user.service";

const TargetFilter = ({ initialValue, onChange, onFindExact }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <OutlinedInput
      size="small"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => onChange(value)}
            edge="end"
          >
            <GpsFixed />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export const SuppliersListContainer = (props: {
  onImport: () => void;
  onManageMedia: (promoItem: any) => void;
  staticFilters?: { [key: string]: any };
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const features = useFeatures();
  useEffect(() => {
    setFilters({
      ...filters,
      childrenOf: searchParams.get("childrenOf"),
    });
  }, [searchParams]);
  const activeUser = useActiveUser();
  const { staticFilters } = props;
  const navigate = useNavigate();
  const deleteConfirmation = useConfirmation({
    title: "Are you sure?",
  });
  const rejectJustification = useJustification({
    title: "Please provide a reason for rejection",
  });
  const [historyItem, setHistoryItem] = useState<{
    materialId?: string | number;
    pslId?: string | number;
  } | null>(null);
  const [rowCount, setRowCount] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState({
    spendCatL1s: [],
    spendCatL2s: [],
    spendCatL3s: [],
    companies: [],
    markets: [],
    statuses: [],
    complianceStatuses: [],
    suppliers: [],
    materials: [],
    businessUnits: [],
    strategicBuyers: [],
    pslStatuses: [],
    supplierDiversity: [],
    wayOfBuying: [],
    scope: [],
    time: null,
    exactId: null,
    taskKind: null,
    createdByUserId: null,
    fromSpend: "",
    toSpend: "",
    childrenOf: searchParams.get("childrenOf") || "",
    currencySpend: "",
    plants: [],
    orderBy: null,
    pslCreationAndValidityDate: null,
    zones: [],
    pslIds: [],
    pslSearch: null,
    materialSearch: null,
    showObsolete: false,
    FavoriteSwitchOn: false,
    supplierDescription: [],
    catalogType: [features["campaigns-module"] ? { id: 1, title: "All Catalogues" } : { id: 2, title: "General Catalogues" }],
    ...(staticFilters || {}),
  });
  const [filterBy, setFilterBy] = useState("all");
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "createdOn",
      desc: true,
    },
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { mutate: exportMaterials, isPending: exportingMaterials } =
    useMutation({
      mutationKey: [
        "export-materials",
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        staticFilters,
        filters,
      ],
      mutationFn: async () => {
        // export logic here
        const response = await PromoItemsService.exportMaterials({
          ...filters,
          searchTerm: !!filterBy || !globalFilter ? null : globalFilter,
          take: pagination.pageSize,
          skip: pagination.pageIndex * pagination.pageSize,
          orderBy: sorting[0]
            ? {
              field: capitalizeFirstLetter(sorting[0].id),
              direction: sorting[0].desc ? "desc" : "asc",
            }
            : null,
          pslSearch:
            !!filterBy && !!globalFilter
              ? {
                [filterBy]: globalFilter,
              }
              : null,
          ...(staticFilters || {}),
        });
        saveAs(response, "materials.xlsx");
      },
    });

  const { mutateAsync: deletePSl, isPending: deletingPsl } = useMutation({
    mutationKey: ["deletePsl"],
    mutationFn: (pslId: string | number) =>
      PromoItemsService.deletePsl({ pslId }),
  });
  const { mutateAsync: approvePSl, isPending: approvingPsl } = useMutation({
    mutationKey: ["approvePsl"],
    mutationFn: (pslId: string | number) =>
      PromoItemsService.approvePsl({ pslId }),
  });
  const { mutateAsync: approveQuality, isPending: approvingQuality } = useMutation({
    mutationKey: ["approveQuality"],
    mutationFn: (pslId: string | number) =>
      PromoItemsService.approveQuality({ pslId }),
  });
  const { mutateAsync: rejectPSl, isPending: rejectingPsl } = useMutation({
    mutationKey: ["rejectPsl"],
    mutationFn: ({
      pslIds,
      comment,
    }: {
      pslIds: (string | number)[];
      comment: string;
    }) => PromoItemsService.rejectPsls({ pslIds, comment }),
  });
  const { mutateAsync: rejectQuality, isPending: rejectingQuality } = useMutation({
    mutationKey: ["rejectQuality"],
    mutationFn: ({
      pslIds,
      comment,
    }: {
      pslIds: (string | number)[];
      comment: string;
    }) => PromoItemsService.rejectQuality({ pslIds, comment }),
  });

  const {
    data: backendData,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: [
      "suppliers-list",
      globalFilter, //re-fetch when global filter changes
      filterBy,
      filters,
      pagination.pageIndex, //re-fetch when page index changes
      pagination.pageSize, //re-fetch when page size changes
      staticFilters,
      sorting,
    ],
    enabled: true,
    retry: 1,
    queryFn: async () => {
      const response = await PromoItemsService.getPromoEntries({
        ...filters,
        searchTerm: !!filterBy || !globalFilter ? null : globalFilter,
        take: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting[0]
          ? {
            field: capitalizeFirstLetter(sorting[0].id),
            direction: sorting[0].desc ? "desc" : "asc",
          }
          : null,
        pslSearch:
          !!filterBy && !!globalFilter
            ? {
              [filterBy]: globalFilter,
            }
            : null,
        ...(staticFilters || {}),
      });
      setRowCount(response?.total || 0); //set the total row count for pagination
      return response;
    },
  });

  useEffect(() => {

    const getcompCodeId = async () => {

      const compCodes = await UserService.fetchUserScope(true) as IUserScopeCompany[];
      const prefferedCompCode = compCodes.filter(item => item.code === activeUser.userPreferredCompanyCode)[0];
      if (!prefferedCompCode) return; //safety check

      setFilters(prevState => {
        return {
          ...prevState,
          companies: [{
            id: prefferedCompCode?.id, title: `${prefferedCompCode.code} - ${prefferedCompCode.name}`
          }]
        }
      })
    };

    getcompCodeId();

  }, []);

  const columns = useMemo<any[]>(
    () => [
      {
        accessorKey: "pslId", //access nested data with dot notation
        header: "Catalogue ID",
        size: 150,
        Cell: ({ renderedValue, row }) => {
          return (
            <NavLink to={`/suppliers/${row.original.pslId}/edit`}>
              {row.original.pslIdWithPrefix || row.original.pslId}
            </NavLink>
          );
        },
      },

      {
        accessorKey: "formStatus",
        header: "Catalogue Status",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          const status = getFormStatus(row.original, 60); // TODO: this 60 is the pslExpiryDays from the active user dto
          switch (status) {
            case "Pending Localization-VFT Maintenance":
              return (
                <Tooltip
                  placement={"right"}
                  title={
                    "There is no linkage existing between Parent & Child Vendor for the Market in Vendor Family Tree ( VFT) in Master Data"
                  }
                >
                  <div>{status}</div>
                </Tooltip>
              );
            default:
              return <span>{status}</span>;
          }
        },
      },
      {
        accessorKey: "Scope",
        header: "Scope",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          const getScope = (id: number) =>
            id === 0 ? "At Market" : "Above Market";
          const findScopeLocalChild = (pslIdWithPrefix) => {
            if (pslIdWithPrefix.charAt(0) === "L") {
              return "- Local Item";
            } else if (pslIdWithPrefix.charAt(0) === "C") {
              return "- Child Item";
            } else {
              return "";
            }
          };
          return (
            <span>
              {getScope(row.original?.scope)}{" "}
              {findScopeLocalChild(row.original.pslIdWithPrefix)}
            </span>
          );
        },
      },

      {
        accessorKey: "supplierCode",
        header: "Parent Vendor",
        size: 250,
        Cell: ({ renderedValue, row }) => {
          if (row.original?.childSupplierCode && row.original?.scope === 1) {
            return (
              <span>
                {row.original?.childSupplierCode} -{" "}
                {row.original?.childSupplierName}
              </span>
            );
          }
          if (row.original?.parentCode && row.original?.scope === 0) {
            return (
              <span>
                {row.original?.parentCode} - {row.original?.parentName}
              </span>
            );
          }
          return "";
        },
      },
      {
        accessorKey: "childSupplierCode",
        header: "Child Vendor",
        size: 250,
        Cell: ({ renderedValue, row }) => {
          return row.original?.childSupplierCode &&
            row.original?.scope !== 1 ? (
            <u>
              {row.original?.childSupplierCode} -{" "}
              {row.original?.childSupplierName}
            </u>
          ) : null;
        },
      },
      {
        accessorKey: "childSupplierCodeLocal",
        header: "Local Vendor",
        size: 250,
        Cell: ({ renderedValue, row }) => {
          return row.original?.childSupplierCode &&
            row.original?.scope !== 1 ? (
            <u>
              {row.original?.childSupplierCode} -{" "}
              {row.original?.childSupplierName}
            </u>
          ) : null;
        },
      },
      {
        accessorKey: "pslVendorContactName",
        header: "Supplier Contact",
        size: 250,
        enableSorting: false,
      },
      {
        accessorKey: "statusId",
        header: "Supplier Status",
        size: 250,
        Cell: ({ row }) => {
          return row.original.statusId !== SupplierStatus.BLANK ? (
            <Chip
              size="small"
              label={Object.keys(SupplierStatus)
                .find((key) => SupplierStatus[key] === row.original.statusId)
                ?.replace("_", " ")}
            />
          ) : null;
        },
      },
      {
        accessorKey: "businessUnits",
        header: "Business Unit",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <div style={{ maxWidth: "240px" }}>
              {row.original?.businessUnits?.map((el) => (
                <Chip key={el.id} label={el.name} variant="outlined" />
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "validFrom",
        header: "Valid From",
        size: 250,
        Cell: ({ row }) => {
          return <>{moment(row.original.validFrom).format("DD/MM/yyyy")}</>;
        },
      },
      {
        accessorKey: "validTo",
        header: "Valid To",
        size: 250,
        Cell: ({ row }) => {
          return <>{moment(row.original.validTo).format("DD/MM/yyyy")}</>;
        },
      },
      {
        accessorKey: "zones",
        header: "Zones",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <ItemsCounter
              data={row.original?.zones?.map((zone) => zone.name)}
              singularName="Zone"
              pluralName="Zones"
            />
          );
        },
      },
      {
        accessorKey: "markets",
        header: "Markets",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <div>
              {/* {row.original?.markets?.map((el, i) => (
                <div key={i}>{el.name}</div>
              ))} */}
              <ItemsCounter
                data={row.original?.markets?.map((market) => market.name)}
                singularName="Market"
                pluralName="Markets"
              />
            </div>
          );
        },
      },
      {
        accessorKey: "companyCodes",
        header: "Company Codes",
        size: 250,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <div>
              <ItemsCounter
                data={row.original?.companyCodes?.map(
                  (companyCode) => `[${companyCode.code}] ${companyCode.name}`
                )}
                singularName="Company Code"
                pluralName="Company Codes"
              />
              {/* {row.original?.companyCodes?.map((el, i) => (
                <div key={i}>
                  {el.code} - {el.name}
                </div>
              ))} */}
            </div>
          );
        },
      },
      {
        accessorKey: "agreements",
        header: "Agreements",
        size: 250,
        enableSorting: false,
      },
      {
        accessorKey: "strategicBuyerEmailAddress",
        header: "Buyer Name",
        size: 250,
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.hideBuyer
                ? ""
                : row.original?.strategicBuyerEmailAddress}
            </div>
          );
        },
      },
      {
        accessorKey: "marketContactName",
        header: "Market Contact",
        size: 250,
      },
      {
        accessorKey: "teamName",
        header: "Team Name",
        size: 250,
      },
      {
        accessorKey: "noteRequester",
        header: "Note to Requester",
        size: 150,
        enableSorting: false,
      },
      {
        accessorKey: "noteLocalProcurement",
        header: "Note to Local Pr.",
        size: 150,
        enableSorting: false,
      },
      {
        accessorKey: "tenderNumber",
        header: "Tender Number",
        size: 150,
      },
      {
        accessorKey: "priceList",
        header: "Price List",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          return row.original?.priceList?.startsWith("http") ? (
            <Link
              href={row.original?.priceList}
              target="_blank"
              rel="noreferrer noopener"
            >
              {row.original?.priceList}
            </Link>
          ) : (
            <>{row.original?.priceList}</>
          );
        },
      },
      {
        accessorKey: "statusJustification",
        header: "Status Justification",
        size: 250,
        enableSorting: false,
      },
      {
        accessorKey: "rejectionComment",
        header: "Rejection Comment",
        size: 250,
        enableSorting: false,
      },
      {
        accessorKey: "unitPrice",
        header: "Price Available",
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <span>
              {row.original?.priceList || row.original?.unitPrice
                ? "Yes"
                : "No"}
            </span>
          );
        },
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 250,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.createdOn
                ? moment(row.original.createdOn).format("DD/MM/yyyy")
                : ""}
            </>
          );
        },
      },
      {
        accessorKey: "createdByUserEmail",
        header: "Created By",
        size: 250,
        Cell: ({ row }) => {
          return <span>{row.original?.createdByUser?.email}</span>;
        },
      },
      {
        accessorKey: "modifiedOn",
        header: "Updated On",
        size: 250,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.modifiedOn
                ? moment(row.original.modifiedOn).format("DD/MM/yyyy")
                : ""}
            </>
          );
        },
      },
      {
        accessorKey: "modifiedByUserEmail",
        header: "Updated By",
        size: 250,
        Cell: ({ row }) => {
          return <span>{row.original?.modifiedByUser?.email}</span>;
        },
      },
      {
        accessorKey: "relatedToEasyBuy",
        header: "Easy Buy",
        size: 150,
        Cell: ({ row }) => {
          return <span>{row.original?.relatedToEasyBuy ? "Yes" : "No"}</span>;
        },
      },
      // {
      //   accessorKey: "statusAttachments",
      //   header: "Attachments",
      //   size: 150,
      //   enableSorting: false,
      //   Cell: ({ row }) => {
      //     return <span>TODO</span>; // TODO: implement attachments
      //   },
      // },
      {
        accessorKey: "plants",
        header: "Plants",
        size: 150,
        enableSorting: false,
        Cell: ({ renderedValue, row }) => {
          return (
            <span>
              {row.original?.plants?.map((el, i) => (
                <div key={i}>
                  {el.code} - {el.name}
                </div>
              ))}
            </span>
          );
        },
      },
      {
        accessorKey: "approvers",
        header: "Approvers",
        size: 150,
        enableSorting: false,
        Cell: ({ renderedValue, row }) => {
          return (
            <ItemsCounter
              data={row.original?.approvers}
              singularName="Approver"
              pluralName="Approvers"
            />
          );
          // row.original.approvers?.map((approver) => (
          //     <div>{approver}</div>
          //   ));
        },
      },
      {
        accessorKey: "diversity",
        header: "Supplier Diversity",
        size: 150,
        enableSorting: false,
        Cell: ({ renderedValue, row }) => {
          return row.original.diversity?.map((el, i) => (
            <Tooltip title={el.text}>
              <Chip
                key={i}
                variant="outlined"
                icon={
                  el.defined ? (
                    <Done style={{ color: "green", fontSize: ".8rem" }}></Done>
                  ) : (
                    <Close style={{ color: "red", fontSize: ".8rem" }}></Close>
                  )
                }
                label={el.code}
              ></Chip>
            </Tooltip>
          ));
        },
      },
    ],
    []
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      let value = inputRef.current.value.trim();
      // remove the 2 chars prefix if it's not a number
      if (value.length >= 2 && isNaN(parseInt(value.slice(0, 2)))) {
        value = value.slice(2);
      }
      setFilters((filters) => ({
        ...filters,
        exactId: value,
      }));
    }
  };
  const data = useMemo(() => {
    return backendData?.data || [];
  }, [backendData]);

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableRowActions: true,
    enableColumnOrdering: true,
    enableRowSelection:
      [
        MyTasksAtMarketTabs.APPROVAL,
        MyTasksAtMarketTabs.QUALITY,
        MyTasksAtMarketTabs.JUSTIFIED,
        MyTasksAtMarketTabs.NOTJUSTIFIED,
      ].indexOf(staticFilters?.taskKind) > -1,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { maxHeight: "calc(100vh - 300px)" } },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    mrtTheme: {
      baseBackgroundColor: "#fff",
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    enableFilters: false,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    initialState: {
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: {
        plants: false,
      },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <div>
          <ButtonGroup color="inherit">
            {!staticFilters?.taskKind && activeUser.isAtMarket() && (
              <Button
                onClick={() => navigate("/suppliers/new")}
                startIcon={<Add />}
              >
                New
              </Button>
            )}
            {staticFilters?.taskKind === MyTasksAtMarketTabs.QUALITY && (
              <>
                <Button
                  disabled={
                    !table.getSelectedRowModel().rows.length ||
                    approvingQuality ||
                    rejectingQuality
                  }
                  startIcon={<ThumbUp />}
                  onClick={() => {
                    Promise.all(
                      table
                        .getSelectedRowModel()
                        .rows.map((row) => approveQuality(row.original.pslId))
                    ).then(() => {
                      table.setRowSelection({});
                      toast.success("Quality approved successfully");
                      refetch();
                    }).catch((e) => {
                    });
                  }}
                >
                  Approve
                </Button>
                <Button style={{ borderRadius: 0 }}
                  disabled={
                    !table.getSelectedRowModel().rows.length ||
                    approvingQuality ||
                    rejectingQuality
                  }
                  startIcon={<ThumbDown />}
                  onClick={() => {
                    rejectJustification
                      .requestJustification()
                      .then(({ justification }) => {
                        rejectQuality({
                          pslIds: table
                            .getSelectedRowModel()
                            .rows.map((row) => row.original.pslId),
                          comment: justification,
                        }).then(() => {
                          table.setRowSelection({});
                          toast.success("Quality rejected successfully");
                          refetch();
                        });
                      })
                      .catch((e) => {
                        console.error(e);
                      });
                  }}
                >
                  Reject
                </Button>
              </>
            )}
            {staticFilters?.taskKind === MyTasksAtMarketTabs.APPROVAL && (
              <>
                <Button
                  disabled={
                    !table.getSelectedRowModel().rows.length ||
                    approvingPsl ||
                    rejectingPsl
                  }
                  startIcon={<ThumbUp />}
                  onClick={() => {
                    Promise.all(
                      table
                        .getSelectedRowModel()
                        .rows.map((row) => approvePSl(row.original.pslId))
                    ).then(() => {
                      table.setRowSelection({});
                      toast.success("Promo(s) approved successfully");
                      refetch();
                    });
                  }}
                >
                  Approve
                </Button>
                <Button style={{ borderRadius: 0 }}
                  disabled={
                    !table.getSelectedRowModel().rows.length ||
                    approvingPsl ||
                    rejectingPsl
                  }
                  startIcon={<ThumbDown />}
                  onClick={() => {
                    rejectJustification
                      .requestJustification()
                      .then(({ justification }) => {
                        rejectPSl({
                          pslIds: table
                            .getSelectedRowModel()
                            .rows.map((row) => row.original.pslId),
                          comment: justification,
                        }).then(() => {
                          table.setRowSelection({});
                          toast.success("Promo(s) rejected successfully");
                          refetch();
                        });
                      })
                      .catch((e) => {
                        console.error(e);
                      });
                  }}
                >
                  Reject
                </Button>
              </>
            )}
            {!!staticFilters?.taskKind && (
              <Button
                disabled={exportingMaterials}
                onClick={() => {
                  exportMaterials();
                }}
                startIcon={
                  exportingMaterials ? <CircularProgress size="16px" /> : <Download />
                }
              >
                Export all results
              </Button>
            )}
          </ButtonGroup>
        </div>
      );
    },
    renderToolbarInternalActions: ({ table }) => (
      <>
        <TextField
          size="small"
          placeholder="Catalogue ID"
          inputRef={inputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClick} edge="end">
                  <GpsFixedRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* built-in buttons (must pass in table prop for them to work!) */}
        <TextField
          size="small"
          placeholder="Start typing to search..."
          value={table.getState().globalFilter}
          onChange={(e) => table.setGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Select
                  id="input-search-by"
                  sx={{ width: 150, ml: -2 }}
                  size="small"
                  variant="outlined"
                  label="Search By"
                  placeholder="Search By"
                  value={filterBy}
                  onChange={(e) => {
                    setFilterBy(e.target.value);
                    table.setGlobalFilter("");
                  }}
                >
                  {[
                    { value: "all", label: "All" },
                    { value: "material", label: "Material" },
                    {
                      value: "materialDescription",
                      label: "Material Description",
                    },
                    { value: "supplier", label: "Supplier" },
                    { value: "shortDescription", label: "Short Description" },
                    {
                      value: "scopeOfApplication",
                      label: "Scope of Application",
                    },
                    { value: "keywords", label: "Keywords" },
                  ].map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => table.setGlobalFilter("")}
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <MRT_ToggleGlobalFilterButton table={table} /> */}
        {/* <MRT_ToggleFiltersButton table={table} /> */}
        <Tooltip title="Filters">
          <IconButton onClick={() => setFiltersOpen(true)}>
            <FilterList />
          </IconButton>
        </Tooltip>
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />

        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      const currentPsl = new PSL(row.original);
      // {(props.onReactivate && (isPslArchivedAndNotAChild() || isChildDeleted()) && !isObsoleteChild()) &&
      //   <MenuItem key={"reactivate-psl"} onClick={reactivatePsl}>Reactivate</MenuItem>
      //   || [
      //     !isPslChildArchived() && <MenuItem key={"edit-psl"} onClick={editPsl}>Edit Form</MenuItem>,
      //     !isChildPsl && <MenuItem key={"add-material"} onClick={addMaterials}>Add Materials</MenuItem>,
      //     isLocalizedTabOrNotPendingChild && <MenuItem key={"delete-psl"} onClick={deletePsl}>Delete Form</MenuItem>
      //   ]
      // }
      return [
        ...(((currentPsl.isArchived() && !currentPsl.isChildPsl()) ||
          currentPsl.isChildPslDeleted()) &&
          !currentPsl.isObsoleteChildPsl()
          ? [
            <MenuItem
              key="reactivate"
              onClick={() => {
                navigate("/suppliers/" + row.original.pslId + "/edit");
              }}
            >
              <ListItemIcon>
                <ReplayOutlined />
              </ListItemIcon>
              Reactivate
            </MenuItem>,
          ]
          : [
            !(currentPsl.isOnlyArchived() && currentPsl.isChildPsl())
            && !activeUser.isViewOnly()
            && (
              <MenuItem
                onClick={() => {
                  navigate("/suppliers/" + row.original.pslId + "/edit");
                }}
              >
                <ListItemIcon>
                  <EditOutlined />
                </ListItemIcon>
                Edit Form
              </MenuItem>
            ),
            !currentPsl.isChildPslWithAnyPendingChanges() && (
              <MenuItem
                disabled={deletingPsl}
                onClick={() => {
                  closeMenu();
                  deleteConfirmation
                    .requestConfirmation()
                    .then(() => {
                      deletePSl(row.original.pslId).then(() => {
                        toast.success("Form deleted successfully");
                        refetch();
                      });
                    })
                    .catch((e) => {
                      console.log("rejected");
                    });
                }}
              >
                <ListItemIcon>
                  <DeleteOutline />
                </ListItemIcon>
                Delete Form
              </MenuItem>
            ),
          ]),
        <MenuItem
          onClick={() => {
            closeMenu();
            setHistoryItem({ pslId: row.original.pslId });
          }}
        >
          <ListItemIcon>
            <HistoryOutlined />
          </ListItemIcon>
          Promo History
        </MenuItem>,
        ...(currentPsl.isAboveMarket() &&
          row.original.deleted === false &&
          activeUser.hasAboveMarketRole()
          ? [
            <MenuItem
              onClick={() => {
                navigate(`/suppliers?childrenOf=${row.original.pslId}`);
              }}
            >
              <ListItemIcon>
                <MoveDownOutlined />
              </ListItemIcon>
              View Children
            </MenuItem>,
          ]
          : []),
        ...(currentPsl.isChildPsl()
          ? [
            <MenuItem
              onClick={() => {
                navigate(`/suppliers/${row.original.parentId}/edit`);
              }}
            >
              <ListItemIcon>
                <MoveUpOutlined />
              </ListItemIcon>
              View Parent
            </MenuItem>,
          ]
          : []),
      ];
    },
    renderDetailPanel: ({ row }) => <DetailsPanel row={row} />,
    muiTablePaperProps: ({ table }) => ({
      //not sx
      style: {
        zIndex: table.getState().isFullScreen ? 1300 : undefined,
      },
    }),
  });

  return (
    <>
      {deleteConfirmation.JSX}
      {rejectJustification.JSX}
      <Drawer
        open={!!historyItem}
        anchor="right"
        PaperProps={{ sx: { width: 420 } }}
        onClose={() => setHistoryItem(null)}
      >
        <PromoHistory {...(historyItem || {})} />
      </Drawer>
      <PslFiltersContainer
        open={filtersOpen}
        initialData={filters}
        onClose={() => setFiltersOpen(false)}
        onApply={(filters) => {
          setFilters(filters);
          setFiltersOpen(false);
        }}
      />
      <MaterialReactTable table={table} />
    </>
  );
};

const DetailsPanel = ({ row }) => {
  const [rowCount, setRowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdOn", desc: true },
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: backendData,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: [
      "materials-list",
      globalFilter, //re-fetch when global filter changes
      pagination.pageIndex, //re-fetch when page index changes
      pagination.pageSize, //re-fetch when page size changes
      sorting,
      row.original.pslId,
    ],
    enabled: true,
    retry: 1,
    queryFn: async () => {
      console.log("sorting", { sorting });
      const response = await PromoItemsService.getMaterials({
        searchTerm: globalFilter,
        take: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        spendCatL1s: [],
        spendCatL2s: [],
        spendCatL3s: [],
        companies: [],
        markets: [],
        statuses: [],
        complianceStatuses: [],
        suppliers: [],
        materials: [],
        businessUnits: [],
        strategicBuyers: [],
        pslStatuses: [],
        supplierDiversity: [],
        wayOfBuying: [],
        scope: [],
        time: null,
        exactId: null,
        taskKind: null,
        createdByUserId: null,
        fromSpend: "",
        toSpend: "",
        childrenOf: "",
        currencySpend: "",
        plants: [],
        orderBy: sorting[0]
          ? {
            field: capitalizeFirstLetter(sorting[0].id),
            direction: sorting[0].desc ? "desc" : "asc",
          }
          : null,
        pslCreationAndValidityDate: null,
        zones: [],
        pslIds: [{ id: row.original.pslId }],
        pslSearch: null,
        materialSearch: null,
        showObsolete: false,
        FavoriteSwitchOn: false,
        supplierDescription: [],
      });
      setRowCount(response?.total || 0); //set the total row count for pagination
      return response;
    },
  });
  const data = useMemo(() => {
    return backendData?.data || [];
  }, [backendData]);
  return isLoading ? (
    <LinearProgress />
  ) : (
    <MaterialsListContainer
      rowCount={rowCount}
      setRowCount={setRowCount}
      data={data}
      pagination={pagination}
      setPagination={setPagination}
      sorting={sorting}
      setSorting={setSorting}
      refetch={refetch}
      isChild
    />
  );
};

export default SuppliersListContainer;
