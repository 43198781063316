import React from "react";
import { StaticDataContext } from "../containers/StaticDataProvider.container";

export const useStaticData = () => {
  const context = React.useContext(StaticDataContext);
  if (context === undefined) {
    throw new Error(
      "useStaticData must be used within a StaticDataProvider"
    );
  }
  return context;
};