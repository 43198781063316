import { CurrencyData } from "modules/PromoItems/services/promoItems.service";
import { IdNamePair } from "service/generic.types";
import {
  createQueryService,
} from "utils/factories";

export default class StaticDataService {
  static readonly fetchCategoryTree = createQueryService<any[]>(
    "/api/PslSpendCat/GetPslSpendCat"
  );

  static readonly fetchBusinessUnits = createQueryService<IdNamePair[]>(
    "/api/HackathonPsl/GetPslBusinessUnits"
  );

  static readonly fetchCurrencies: () => CurrencyData[] = () => {
    return [
      { value: "USD", label: "USD" },
      { value: "EUR", label: "EUR" },
      { value: "CHF", label: "CHF" },
    ].map((c) => new CurrencyData(c.value, c.label));
  }

  static readonly fetchLanguages = createQueryService<
    any,
    { searchTerm: string }
  >(`/api/HackathonPsl/GetLanguages`);
}
