import React from "react";
import {
  Box,
  CircularProgress,
  Typography,
  LinearProgress,
} from "@mui/material";

import { ReactComponent as PromoLogo } from "assets/images/promo-logo.svg";

export const SplashScreen = (props: {
  message?: string;
  status?: "loading" | "error" | "idle";
}) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        flexDirection: "column",
        background: "#eee",
        transition: "all 0.2s ease-in-out",
        ...(props.status === "idle"
          ? {
              opacity: 0,
              pointerEvents: "none",
              transform: "scale(1.2)",
            }
          : {}),
      }}
    >
      <Box sx={{ width: 512, maxWidth: "100%", mb: 8, color: "#555" }}>
        <PromoLogo/>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        {props.status === "loading" && <CircularProgress />}{" "}
        <Typography variant="body1" fontSize={20} fontWeight={100}>
          {" "}
          {props.message ||
            (props.status === "loading"
              ? "Loading..."
              : props.status === "error"
              ? "Something went wrong."
              : "")}
        </Typography>
      </Box>
      {props.status === "loading" && (
        <Box sx={{ width: 320 }}>
          <LinearProgress
            sx={{ position: "fixed", top: 0, left: 0, right: 0 }}
          />
          <LinearProgress
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          />
        </Box>
      )}
    </Box>
  );
};

export default SplashScreen;
