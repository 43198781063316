import { useMutation, useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
import React, { useState } from "react";
import CampaignsService from "../service/campaigns.service";
import moment from "moment";
import { MenuItem, Tooltip } from "@mui/material";
import CampaignMaterialForm from "../components/CampaignMaterialForm.component";
import CreateCampaignContainer from "./create.container";
import Drawer from "modules/UI/components/Drawer.component";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const CampaignsMaintenanceContainer = () => {
  const [drawerInitialData, setDrawerInitialData] = useState(null);
  const {
    data: allCampaigns,
    refetch: refetchAllCampaigns,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["campaigns/all"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
      });
      return response;
    },
    // initialData: { data: [], take: 100, skip: 0, total: 0 },
  });
  const { mutateAsync: archiveCampaign, isPending: archivingCampaign } =
    useMutation<any, any, { campaignId: string | number }>({
      mutationKey: ["archiveCampaign"],
      mutationFn: async ({ campaignId }: { campaignId: string | number }) => {
        await CampaignsService.archiveCampaign({ campaignId });
      },
      onSuccess: () => {
        toast.success("Campaign archived successfully!");
        refetchAllCampaigns();
      },
    });
  const { mutateAsync: publishCampaign, isPending: publishingCampaign } =
    useMutation({
      mutationKey: ["publishCampaign"],
      mutationFn: async ({ campaignId }: { campaignId: string | number }) => {
        await CampaignsService.publishCampaign({ campaignId });
        return campaignId;
      },
      onSuccess: () => {
        toast.success("Campaign published successfully!");
        refetchAllCampaigns();
      },
    });
  return (
    <>
      <Drawer
        title="Edit Campaign"
        open={!!drawerInitialData}
        onClose={() => setDrawerInitialData(null)}
      >
        {!!drawerInitialData && (
          <CreateCampaignContainer
            onClose={() => {
              setDrawerInitialData(null);
              refetchAllCampaigns();
            }}
            initialData={drawerInitialData}
          />
        )}
      </Drawer>
      <MaterialReactTable
        state={{
          isLoading,
          showProgressBars: isRefetching,
        }}
        enableRowActions
        renderRowActionMenuItems={({ row }) => {
          return [
            <MenuItem
              onClick={() => setDrawerInitialData(row.original)}
              key={row.id + "-edit"}
              disabled={
                ["Draft", "Active", "Future", "Past", "Archived"][
                  row.original.state
                ] !== "Draft"
              }
            >
              Edit
            </MenuItem>,
            <MenuItem
              onClick={() => setDrawerInitialData(row.original)}
              key={row.id + "-publish"}
              disabled={
                publishingCampaign ||
                ["Draft", "Active", "Future", "Past", "Archived"][
                  row.original.state
                ] !== "Draft"
              }
            >
              Publish Campaign
            </MenuItem>,
            <MenuItem
              onClick={() => setDrawerInitialData(row.original)}
              key={row.id + "-archive"}
              disabled={archivingCampaign || row.original.isDeleted}
            >
              Archive Campaign
            </MenuItem>,
          ];
        }}
        initialState={{
          pagination: {
            pageIndex: 0,
            pageSize: 100,
          },
          columnPinning: {
            left: ["mrt-row-expand", "mrt-row-select"],
            right: ["mrt-row-actions"],
          },
        }}
        mrtTheme={{
          baseBackgroundColor: "#fff",
        }}
        columns={[
          {
            accessorKey: "id",
            header: "ID",
            Cell: ({ row }) => {
              return (
                <Link to={`/campaigns/${row.original.id}`}>
                  {row.original.idWithPrefix}
                </Link>
              );
            },
          },
          {
            accessorKey: "name",
            header: "Name",
          },
          {
            accessorKey: "description",
            header: "About",
          },
          {
            accessorKey: "state",
            header: "State",
            Cell: ({ row }) => {
              return (
                <>
                  {
                    ["Draft", "Active", "Future", "Past", "Archived"][
                      row.original.state
                    ]
                  }
                </>
              );
            },
          },
          {
            accessorKey: "startDate",
            header: "Start Date",
            Cell: ({ row }) => {
              return (
                <>
                  {moment(row.original.startDate).format("DD/MM/YYYY")}
                  <br />({moment(row.original.startDate).fromNow()}){" "}
                </>
              );
            },
          },
          {
            accessorKey: "endDate",
            header: "End Date",
            Cell: ({ row }) => {
              return (
                <>
                  {moment(row.original.endDate).format("DD/MM/YYYY")}
                  <br />({moment(row.original.endDate).fromNow()}){" "}
                </>
              );
            },
          },
          {
            accessorKey: "validTo",
            header: "Prices Valid Until",
            Cell: ({ row }) => {
              return (
                <>
                  {moment(row.original.validTo).format("DD/MM/YYYY")}
                  <br />({moment(row.original.validTo).fromNow()}){" "}
                </>
              );
            },
          },

          {
            accessorKey: "incoTerms",
            header: "Incoterms",
          },
          {
            accessorKey: "materialsCount",
            header: "Materials Count",
          },
          {
            accessorKey: "markets",
            header: "Scope",
            Cell: ({ row }) => {
              return (
                <>
                  {row.original.markets.map((market) => market.name).join(", ")}
                </>
              );
            },
          },
          {
            accessorKey: "supplierName",
            header: "Supplier",
          },
          {
            accessorKey: "supplierContact.email",
            header: "Supplier Contact",
          },
        ]}
        data={allCampaigns?.data || []}
        renderDetailPanel={({ row }) => (
          <CampaignMaterialsListContainer campaignId={row.original.id} />
        )}
      />
    </>
  );
};

const CampaignMaterialsListContainer = (props: {
  campaignId: string | number;
}) => {
  const [drawerInitialData, setDrawerInitialData] = useState(null);
  const {
    data: { data: materials },
    refetch: refetchMaterials,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["materials", props.campaignId],
    queryFn: async () => {
      return await CampaignsService.getCampaignMaterials({
        orderWindowId: parseInt(props.campaignId.toString()),
        take: 100,
        skip: 0,
      });
    },

    initialData: {
      data: [],
      take: 100,
      skip: 0,
      total: 0,
    },
  });
  return (
    <>
      <Drawer
        open={!!drawerInitialData}
        onClose={() => setDrawerInitialData(null)}
        title="Product details"
      >
        <CampaignMaterialForm
          initialValues={drawerInitialData}
          onClose={() => {
            setDrawerInitialData(null);
            refetchMaterials();
          }}
        />
      </Drawer>
      <MaterialReactTable
        state={{
          isLoading,
          showProgressBars: isRefetching,
        }}
        initialState={{
          isLoading,
          pagination: {
            pageIndex: 0,
            pageSize: 100,
          },
          columnPinning: {
            left: ["mrt-row-expand", "mrt-row-select"],
            right: ["mrt-row-actions"],
          },
        }}
        mrtTheme={{
          baseBackgroundColor: "#fff",
        }}
        columns={[
          {
            accessorKey: "id",
            header: "ID",
          },
          {
            accessorKey: "promoCategoryId",
            header: "Sub Category",
          },
          {
            accessorKey: "materialName",
            header: "Material Name",
          },
          {
            accessorKey: "shortDescription",
            header: "Short Description",
          },
          {
            accessorKey: "itemDescription",
            header: "Scope of Application",
          },
          {
            accessorKey: "supplierPartId",
            header: "Supplier Part ID",
          },
          {
            accessorKey: "manufacturerName",
            header: "Manufacturer Name",
          },
          {
            accessorKey: "manufacturerPartNumber",
            header: "Manufacturer Part Number",
          },
          {
            accessorKey: "supplierLeadTime",
            header: "Supplier Lead Time",
          },
          {
            accessorKey: "currencyCode",
            header: "Currency",
          },
          {
            accessorKey: "unitOfMeasure",
            header: "Unit Of Measure",
          },
          {
            accessorKey: "minimumOrderQuantity",
            header: "Minimum Order Quantity",
          },
          {
            accessorKey: "minimumPurchaseQuantity",
            header: "Minimum Pack Quantity",
          },
          {
            accessorKey: "priceRanges",
            header: "Price Range",
            Cell: ({ row }) => {
              return (
                <Tooltip
                  arrow
                  title={
                    <>
                      {[...row.original.priceRanges]
                        .sort((a, b) => {
                          return a.threshold - b.threshold;
                        })
                        .map((range) => (
                          <>
                            {range.threshold}+ - {range.unitPrice}{" "}
                            {row.original.currencyCode}
                            <br />
                          </>
                        ))}
                    </>
                  }
                >
                  <span>
                    {Math.min(
                      ...row.original.priceRanges.map((x) => x.unitPrice)
                    )}{" "}
                    -{" "}
                    {Math.max(
                      ...row.original.priceRanges.map((x) => x.unitPrice)
                    )}{" "}
                    {row.original.currencyCode}
                  </span>
                </Tooltip>
              );
            },
          },
          {
            accessorKey: "keyWords",
            header: "Keywords",
          },
          {
            accessorKey: "languageId",
            header: "Language",
          },
        ]}
        data={materials || []}
      />
    </>
  );
};

export default CampaignsMaintenanceContainer;
