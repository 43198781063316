import { Alert, AlertTitle, List, ListItemButton } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import React, { useState } from "react";
import { PromoMediaManagerDrawer } from "../components/MediaManager.component";
import PromoItemRow from "../components/PromoItemRow.component";
import { useMutation } from "@tanstack/react-query";
import PromoItemsService from "../services/promoItems.service";

export const CreateStepTwoContainer = (props: {
  defaultSpendCategories: { l1: any; l2: any; l3: any };
}) => {
  const { defaultSpendCategories } = props;
  const helpers = useFormikContext<any>();
  const [selectedPromoItem, setSelectedPromoItem] = useState(null);
  const { mutateAsync: uploadPromoMedia, isPending } = useMutation<any, any, any>({
    mutationKey: ["uploadPromoMedia"],
    mutationFn: async (data) => await PromoItemsService.uploadPromoMaterialsMedia(data),
  });

  const { mutateAsync: uploadPromoMediaFromLink } = useMutation<any, any, any>({
    mutationKey: ["uploadPromoMediaFromLink"],
    mutationFn: async ({ link }) =>
      await PromoItemsService.uploadPromoMediaFromLink({ link }),
  });

  const { mutateAsync: deletePromoMedia, isPending: isDeleting } = useMutation<any, any,any>({
    mutationKey: ["deletePromoMedia"],
    mutationFn: async (fileId) => {
      await PromoItemsService.deletePromoMaterialMedia({ fileId });
      return fileId;
    },
  });

  return (
      <List>
        <FieldArray name="materials">
          {(arrayHelpers) => {
            return (
              <>
                <PromoMediaManagerDrawer
                  promoCategoryId={
                    helpers.values.materials[selectedPromoItem]?.promoCategoryId
                  }
                  materialId={
                    helpers.values.materials[selectedPromoItem]?.materialId
                  }
                  open={selectedPromoItem !== null}
                  onClose={() => setSelectedPromoItem(null)}
                  images={
                    selectedPromoItem !== null
                      ? helpers.values.materials[
                          selectedPromoItem
                        ].mediaFiles?.filter((m) => m.isQuality == false)
                      : []
                  }
                  qualityDocs={
                    selectedPromoItem !== null
                      ? helpers.values.materials[
                          selectedPromoItem
                        ].mediaFiles?.filter((m) => m.isQuality == true)
                      : []
                  }
                  loadingQualityDocs={isPending}
                  deletingQualityDocs={isDeleting}
                  onUploadFromUrl={(link) => {
                    uploadPromoMediaFromLink({
                      link,
                      materialId:
                        helpers.values.materials[selectedPromoItem]?.materialId,
                    })
                      .then((response) => {
                        arrayHelpers.replace(selectedPromoItem, {
                          ...arrayHelpers.form.values.materials[
                            selectedPromoItem
                          ],
                          mediaFiles:
                            arrayHelpers.form.values.materials[
                              selectedPromoItem
                            ].mediaFiles.concat(response),
                        });
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  onUpload={(files, data) => {
                    uploadPromoMedia({
                      file: files[0],
                      materialId:
                        helpers.values.materials[selectedPromoItem]?.materialId,
                      ...data,
                    })
                      .then((response) => {
                        arrayHelpers.replace(selectedPromoItem, {
                          ...arrayHelpers.form.values.materials[
                            selectedPromoItem
                          ],
                          mediaFiles:
                            arrayHelpers.form.values.materials[
                              selectedPromoItem
                            ].mediaFiles.concat(response),
                        });
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  onDelete={(mediaId) => {
                    deletePromoMedia(mediaId).then((deletedFileId) => {
                      arrayHelpers.replace(selectedPromoItem, {
                        ...arrayHelpers.form.values.materials[
                          selectedPromoItem
                        ],
                        mediaFiles: arrayHelpers.form.values.materials[
                          selectedPromoItem
                        ].mediaFiles.filter((f) => f.fileId !== deletedFileId),
                      });
                    });
                  }}
                />
                {helpers.values.materials
                  .filter((m) => !m.deleted)
                  .map((promoItem, index) => (
                    <PromoItemRow
                      defaultSpendCategories={defaultSpendCategories}
                      promoItem={promoItem}
                      promoItemIndex={index}
                      onDuplicate={(promoItem) => {
                        arrayHelpers.push({
                          ...promoItem,
                          materialId: null,
                        });
                      }}
                      onRemove={(promoItem) => {
                        if (promoItem.materialId === null) {
                          arrayHelpers.remove(index);
                        } else {
                          arrayHelpers.replace(index, {
                            ...promoItem,
                            deleted: true,
                          });
                        }
                      }}
                      onManageMedia={() => {
                        setSelectedPromoItem(index);
                      }}
                    />
                  ))}
              </>
            );
          }}
        </FieldArray>
        {typeof helpers.getFieldMeta("materials").error === "string" && (
          <Alert severity="error">
            <AlertTitle>
              {helpers.getFieldMeta("materials").error?.toString()}
            </AlertTitle>
          </Alert>
        )}
        <ListItemButton
          disabled={helpers.values.campaignRelated}
          onClick={() => {
            helpers.setFieldValue("materials", [
              ...helpers.values.materials,
              {
                spendCat1Id: helpers.values.vendor.spendCat1Id,
                spendCat1DisplayValue: null,
                spendCat1Code: null,
                spendCat2Id: null,
                spendCat2DisplayValue: null,
                spendCat2Code: null,
                spendCat3Id: null,
                spendCat3DisplayValue: null,
                spendCat3Code: null,
                materialNumber: null,
                materialName: null,
                materialId: null,
                parentMaterialId: null,
                languageId: null,
                languageTag: null,
                keyWords: [],
                unitOfMeasure: "EA",
                itemDescription: null,
                shortDescription:
                  null,
                minimumOrderQuantity: null,
                supplierLeadTime: null,
                currencyCode: "USD",
                matGrp: "",
                supplierPartId: "",
                manufacturerPartNumber: "",
                manufacturerName: "",
                deleted: false,
                waysOfBuying: [1, 2, 3],
                marketContactName: null,
                mediaFiles: [],
                plants: [],
                priceRanges: [{ quantity: 0, price: 0, pricePer: 0 }],
              },
            ]);
          }}
          sx={{
            justifyContent: "center !important",
            textTransform: "uppercase",
          }}
        >
          Add new material
        </ListItemButton>
      </List>
  );
};
export default CreateStepTwoContainer;
