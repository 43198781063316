import {FormControl, FormHelperText, InputLabel, ListItemIcon, ListSubheader, MenuItem, Select,} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import AdminService from "modules/Admin/service/admin.service";

import React, {useEffect} from "react";
import {toTitleCase} from "utils/utils";

export const PromoCategorySelect = (props: {
  error?: string;
  value: string;
  disabled?: boolean;
  onChange: (e: any) => void;
  qualityApprovalRequired?: (e: any) => void;
}) => {
  const { data: promoCategories, isLoading: isLoadingCategories, isFetched } = useQuery({
    queryKey: ["promo-categories/get-all"],
    queryFn: async () => {
      return await AdminService.getAllPromoCategories();
    },
    initialData: [],
  });
  useEffect(() => {
    if (isFetched && props.value && props.qualityApprovalRequired) {
      props.qualityApprovalRequired(promoCategories.find((c) => c.id === props.value)?.qualityControl)
    }
  }, [props.value, isFetched]);
  return (
    <FormControl fullWidth size="small" disabled={props.disabled}>
      <InputLabel htmlFor="grouped-select">
        {`Sub Category *`}
      </InputLabel>
      <Select
          required={true}
        defaultValue=""
        id="grouped-select"
        label="Sub Category"
        error={!!props.error}
        value={props.value}
        disabled={props.disabled || isLoadingCategories}
        onChange={(e) => {
          props.onChange(promoCategories.find((c) => c.id === e.target.value));
        }}
        renderValue={(value) => (
          <>
            {toTitleCase(
              promoCategories.find((c) => c.id === value)?.name || ""
            )}
          </>
        )}
      >
        <ListSubheader>Type A</ListSubheader>
        {promoCategories
          .filter((c) => c.promoCategoryType === 0 && !c.isDeleted)
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              <ListItemIcon
                sx={{
                  width: "2.5em",
                  height: "2.5em",
                  textAlign: "center",
                  p: 1,
                  "& svg": { maxWidth: "100%", maxHeight: "100%" },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.icon,
                  }}
                />
              </ListItemIcon>
              {toTitleCase(category.name)}
            </MenuItem>
          ))}
        <ListSubheader>Type B</ListSubheader>
        {promoCategories
          .filter((c) => c.promoCategoryType === 1 && !c.isDeleted)
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              <ListItemIcon
                sx={{
                  width: "2.5em",
                  height: "2.5em",
                  textAlign: "center",
                  p: 1,
                  "& svg": { maxWidth: "100%", maxHeight: "100%" },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.icon,
                  }}
                />
              </ListItemIcon>
              {toTitleCase(category.name)}
            </MenuItem>
          ))}
        <ListSubheader>Type C</ListSubheader>
        {promoCategories
          .filter((c) => c.promoCategoryType === 2 && !c.isDeleted)
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              <ListItemIcon
                sx={{
                  width: "2.5em",
                  height: "2.5em",
                  textAlign: "center",
                  p: 1,
                  "& svg": { maxWidth: "100%", maxHeight: "100%" },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.icon,
                  }}
                />
              </ListItemIcon>
              {toTitleCase(category.name)}
            </MenuItem>
          ))}
      </Select>
      {!!props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </FormControl>
  );
};

export default PromoCategorySelect;
