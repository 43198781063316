import { CircularProgress } from "@mui/material";
import SplashScreen from "modules/UI/components/SplashScreen.component";
import { UserService } from "modules/User/service/user.service";
import React, { useState, useEffect } from "react";
import { User } from "./service/user";
import { hash } from "crypto";

export const ActiveUserContext = React.createContext({
  activeUser: null,
  userScope: null,
  loading: true,
  error: null,
  fetchUser: () => {},
});

export const ActiveUserProvider = ({ children }) => {
  const [activeUser, setActiveUser] = useState({});
  const [userScope, setUserScope] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchUser = async () => {
    try {
      const user = await UserService.fetchLiteUser();
      const filters = await UserService.fetchUserAppFilters();
      const fullScope = await UserService.fetchUserFullScope();
      const hashMaps = fullScope.reduce(
        (acc, curr) => {
          const currentZone = {
            id: curr.zone.id,
            name: curr.zone.name,
            fullAcces: curr.fullZoneAccess,
          };
          acc.zonesHashmap[curr.zone.id] = currentZone;
          acc.zones.push(currentZone);

          curr.markets.forEach((market) => {
            const currentMarket = {
              id: market.market.id,
              name: market.market.name,
              fullAcces: market.fullMarketAccess,
              zoneId: currentZone.id,
            };
            acc.marketsHashmap[market.market.id] = currentMarket;
            acc.markets.push(currentMarket);

            market.companyCodes.forEach((company) => {
              const currentCompany = {
                id: company.id,
                code: company.code,
                name: company.name,
                marketId: currentMarket.id,
                zoneId: currentZone.id,
              };
              acc.companyCodesHashmap[company.id] = currentCompany;
              acc.companyCodes.push(currentCompany);
            });
          });

          return acc;
        },
        {
          zonesHashmap: {},
          zones: [],
          marketsHashmap: {},
          markets: [],
          companyCodesHashmap: {},
          companyCodes: [],
        }
      );
      setActiveUser({ ...user, filters });
      setUserScope({...hashMaps, scopeTree: fullScope});
      console.log({...hashMaps, scopeTree: fullScope});
    } catch (e) {
      console.log({ error: e });
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <ActiveUserContext.Provider
      value={{ activeUser, loading, error, fetchUser, userScope }}
    >
      {" "}
      <SplashScreen
        message="Loading User details..."
        status={!!loading ? "loading" : !!error ? "error" : "idle"}
      />
      {!loading && !error && children}
    </ActiveUserContext.Provider>
  );
};

export const useActiveUserScope = () => {
  const context = React.useContext(ActiveUserContext);
  if (context === undefined) {
    throw new Error("useActiveUser must be used within a ActiveUserProvider");
  }
  return context.userScope;
};

export const useActiveUser = () => {
  const context = React.useContext(ActiveUserContext);
  if (context === undefined) {
    throw new Error("useActiveUser must be used within a ActiveUserProvider");
  }
  return User.FromBackend(context.activeUser);
};
