import {FormikHelpers, FormikValues} from "formik";
import FormikStep from "modules/Forms/components/formikStep.component";
import FormikStepperProvider from "modules/Forms/components/formikStepperProvider.component";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import PromoItemsService from "../services/promoItems.service";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {toast} from "react-toastify";
import {useActiveUser} from "modules/User/activeUser";
import CreateStepOneContainer from "./createStepOne.container";
import CreateStepTwoContainer from "./createStepTwo.container";
import {PSL} from "../domains/psl.domain";
import {Breadcrumbs, Typography} from "@mui/material";
import {NavigateNext} from "@mui/icons-material";
import {PromoItemRowSchema} from "../services/promoItems.schemas";
import { useStaticData } from "modules/MasterData/service/staticData.hooks";

const testVendorData = {
  pslId: null,
  strategicBuyerEmailAddress: "Ivan.Ivanov@nestle.com",
  teamName: "test",
  agreements: "agreements go here",
  validFrom: "2024-06-11T21:00:00.000Z",
  validTo: "2025-04-25T21:00:00.000Z",
  supplierCode: null,
  supplierName: null,
  childSupplierCode: "0100210002",
  childSupplierName: "Vb-Servicos Comercio E |City: MORUNGABA|",
  statusId: 2,
  pslVendorContactName: "Contact Name",
  pslVendorContactPhone: "Phone",
  pslVendorContactEmail: "Email",
  pslVendorContactUrl: "Website",
  noteRequester: "This is a note to requester",
  noteLocalProcurement: "This is a note to local procurement",
  tenderNumber: "tender number here",
  priceList: "http://www.example.com",
  markets: [],
  companyCodes: [
    {
      id: "1",
      name: "BR10 - Nestle Brasil Ltda",
      code: "BR10",
    },
  ],
  plants: [],
  businessUnits: [
    {
      id: 0,
      name: "All",
    },
  ],
  relatedToEasyBuy: true,
  statusJustification: "Test justification",
  statusAttachments: [],
  yearlySpendCurrencyCode: "CHF",
  yearlySpend: null,
  scope: 3,
  createdByUser: null,
  marketContactName: null,
  parentId: null,
  zones: [],
};

export enum SupplierStatus {
  ALL = 0,
  MANDATORY = 1,
  SOLE_SOURCE = 2,
  VALIDATED = 3,
  BLANK = 4,
}
export const SelectBlank = { value: SupplierStatus.BLANK, label: "‎‎‎‎‏‏‎ ‎" };
export const SelectMandatory = {
  value: SupplierStatus.MANDATORY,
  label: "MANDATORY",
};
export const SelectSoleSource = {
  value: SupplierStatus.SOLE_SOURCE,
  label: "SOLE SOURCE",
};
export const SelectValidated = {
  value: SupplierStatus.VALIDATED,
  label: "VALIDATED",
};

export type CreatePromoItemContainerProps = {};

export const CreatePromoItemContainer: FC<CreatePromoItemContainerProps> = (
  props
) => {
  const { promoId } = useParams();
  const activeUser = useActiveUser();
  const { data: pslInitialData, isLoading: pslLoading } = useQuery({
    queryKey: ["pslInitialData", promoId],
    queryFn: () => PromoItemsService.fetchPslInitialData({ pslId: promoId }),
    staleTime: 0,
    gcTime: 0,
    enabled: !!promoId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  const [defaultSpendCategories, setDefaultSpendCategories] = useState<any>({
    l1: null,
    l2: null,
    l3: null,
  });

  const { spendCategories } = useStaticData()
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState({
    campaignRelated: false,
    vendor: {
      pslId: null,
      promoCategoryId: null,
      promoCategoryQualityRequired: false,
      strategicBuyerEmailAddress: !activeUser.isEditorSupplier()
        ? activeUser.email
        : null,
      teamName: activeUser.jobTitle,
      agreements: null,
      validFrom: new Date().toISOString(),
      validTo: null,
      supplierCode: null,
      supplierName: null,
      childSupplierCode: null,
      childSupplierName: null,
      statusId: activeUser.isEditorSupplier() ? SupplierStatus.MANDATORY : null,
      pslVendorContactName: activeUser.isEditorSupplier()
        ? activeUser.name
        : null,
      pslVendorContactPhone: null,
      pslVendorContactEmail: activeUser.isEditorSupplier()
        ? activeUser.email
        : null,
      pslVendorContactUrl: null,
      noteRequester: null,
      noteLocalProcurement: null,
      tenderNumber: null,
      priceList: null,
      zones: [],
      markets: [],
      companyCodes: [],
      plants: [],
      businessUnits: [],
      relatedToEasyBuy: true,
      statusJustification: null,
      statusAttachments: [],
      yearlySpendCurrencyCode: null,
      yearlySpend: null,
      scope: +(activeUser.hasAboveMarketRole() && !promoId),
      createdByUser: null,
      marketContactName: null,
      parentId: null,
      qualityApprover: null,
      spendCat1Id: null,
      spendCat1DisplayValue: null,
      spendCat1Code: null,
      headerFiles: [],
    },
    materials: [],
  });

  // const [companyCodes, setCompanyCodes] = React.useState([]);
  const createVendorsWithMaterialsMutation = useMutation({
    mutationFn: PromoItemsService.addPslVendorsWithMaterials,
    onSuccess: (data) => {
      toast.success(
        "Catalogue Record created successfully! ID:  " + data?.entity?.pslId
      );
      navigate(`/suppliers`);
    },
  });
  const localizeVendorsWithMaterialsMutation = useMutation({
    mutationFn: PromoItemsService.localizePslVendorsWithMaterials,
    onSuccess: (data) => {
      toast.success(
        `Catalogue Record localized successfully! ID:  ${data?.pslId}`
      );
      navigate(`/suppliers`);
    },
  });
  const updateVendorsWithMaterialsMutation = useMutation({
    mutationFn: PromoItemsService.updatePslVendorsWithMaterials,
    onSuccess: (data) => {
      toast.success(
        `Catalogue Record updated successfully! ID:  ${data?.res?.pslId}`
      );
      navigate(`/suppliers`);
    },
  });

  useEffect(() => {
    if (pslLoading || !pslInitialData) return;
    setInitialData({...pslInitialData, campaignRelated: pslInitialData.vendor.statusJustification.indexOf("Order Campaign Number:") > -1 }); //TODO: change to values.vendor.orderWindowId when the BE is fixed
  }, [pslInitialData]);

  // useEffect(() => {
  //   if (userScope) {
  //     const all = (userScope as IUserScope[]).filter(
  //       (item) => item.fullMarketAccess || item.companyCodes.length > 0
  //     );
  //     let markets = [];
  //     all.forEach(function (full) {
  //       markets[full.market.id] = full.companyCodes.map(
  //         (company) => company.code
  //       );
  //     });
  //     setCompanyCodes(markets);
  //   }
  // }, [userScope]);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && e.ctrlKey) {
        setInitialData((prevData) => ({
          ...prevData,
          vendor: { ...prevData.vendor, ...testVendorData },
        }));
        toast.info("Test data applied");
      }
    });
    return () => {
      window.removeEventListener("keydown", (e) => {
        if (e.key === "Enter" && e.ctrlKey) {
          setInitialData((prevData) => ({
            ...prevData,
            vendor: { ...prevData.vendor, ...testVendorData },
          }));
          toast.info("Test data applied");
        }
      });
    };
  }, []);

  useEffect(() => {
    if (!spendCategories) return;
    const l1 = spendCategories.find((c) => c.code === "S39");
    const l2 = l1?.spendCategories?.find((c) => c.code === "S390036");
    const l3 = l2?.spendCategories?.find((c) => c.code === "S390041");
    if (initialData.materials?.length === 0) {
      setInitialData({
        ...initialData,
        materials: [
          {
            spendCat1Id: l1.id,
            spendCat1DisplayValue: l1.name,
            spendCat1Code: l1.code,
            spendCat2Id: l2.id,
            spendCat2DisplayValue: l2.name,
            spendCat2Code: l2.code,
            spendCat3Id: l3.id,
            spendCat3DisplayValue: l3.name,
            spendCat3Code: l3.code,
            materialNumber: l3.materialCode,
            materialName: l3.materialDescription,
            materialId: null,
            parentMaterialId: null,
            languageId: 47,
            languageTag: null,
            keyWords: [],
            unitOfMeasure: "EA",
            itemDescription: l3.materialDescription,
            shortDescription: l3.materialLongDescription,
            minimumOrderQuantity: 0,
            minimumPurchaseQuantity: 0,
            supplierLeadTime: null,
            currencyCode: "USD",
            matGrp: l3.code,
            supplierPartId: "",
            manufacturerPartNumber: "",
            manufacturerName: "",
            deleted: false,
            waysOfBuying: [1, 2, 3],
            marketContactName: null,
            mediaFiles: [],
            plants: [],
            priceRanges: [{ quantity: 0, price: 0, pricePer: 1 } as any],
          },
        ],
      });
    }
    setDefaultSpendCategories({ l1, l2, l3 });
  }, [spendCategories]);

  return (!!promoId && (!pslInitialData || pslLoading)) ? (
    <>loading...</>
  ) : (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {[
          <NavLink key="1" color="inherit" to="/suppliers" onClick={() => {}}>
            Catalog
          </NavLink>,
          ...(initialData.vendor.parentId
            ? [
                <NavLink
                  key="2"
                  color="inherit"
                  to={`/suppliers/${initialData.vendor.parentId}/edit`}
                  onClick={() => {}}
                >
                  Catalogue Record #{initialData.vendor.parentId}
                </NavLink>,
              ]
            : []),
          <Typography key="3" color="text.primary">
            {promoId ? `Catalogue Record #${promoId}` : "New Catalogue Record"}
          </Typography>,
        ]}
      </Breadcrumbs>
      <FormikStepperProvider
        enableReinitialize
        title={`${promoId ? "Edit" : "Create"} Promo item ${promoId ?? ""}`}
        initialValues={initialData}
        validateOnChange={true}
        validateOnMount={false}
        validateOnBlur={true}
        onSubmit={async function (
          values: FormikValues,
          formikHelpers: FormikHelpers<FormikValues>
        ): Promise<any> {
          const psl = new PSL(values.vendor);
          if (promoId) {
            if (psl.isChildPsl()) {
              await localizeVendorsWithMaterialsMutation.mutateAsync({
                ...values,
                materials: values.materials.map((material) => ({
                  ...material,
                  pslId: promoId,
                })),
              });
            } else {
              await updateVendorsWithMaterialsMutation.mutateAsync({
                ...values,
                materials: values.materials.map((material) => ({
                  ...material,
                  pslId: promoId,
                })),
              });
            }
          } else {
            await createVendorsWithMaterialsMutation.mutateAsync(values);
          }
        }}
        showStepper
      >
        <FormikStep
          title="Supplier information"
          validationSchema={yup.object().shape({
            vendor: yup.object().shape(
              {
                strategicBuyerEmailAddress: yup
                  .string()
                  .required("This field is required."),
                teamName: yup.string().required("This field is required."),
                childSupplierCode: yup
                  .string()
                  .required("This field is required."),
                childSupplierName: yup
                  .string()
                  .required("This field is required."),
                statusId: yup.number().required("This field is required."),
                statusJustification: yup.string().when("statusId", {
                  is: (statusId) =>
                    [
                      SupplierStatus.MANDATORY,
                      SupplierStatus.SOLE_SOURCE,
                    ].includes(statusId),
                  then: (schema) => schema.required("This field is required."),
                  otherwise: (schema) => schema.nullable().notRequired(),
                }),
                qualityApprover: yup.string().notRequired(),
                pslVendorContactName: yup.string().notRequired(),
                pslVendorContactUrl: yup.string().notRequired(),
                pslVendorContactEmail: yup.string().notRequired(),
                pslVendorContactPhone: yup.string().notRequired(),
                zones: yup
                  .array()
                  .test(
                    "zones",
                    "At least one location is required",
                    (value, ctx) => {
                      return (
                        ctx.parent.companyCodes.length > 0 ||
                        ctx.parent.markets.length > 0 ||
                        ctx.parent.zones.length > 0
                      );
                    }
                  ),
                markets: yup
                  .array()
                  .test(
                    "markets",
                    "At least one location is required",
                    (value, ctx) => {
                      return (
                        ctx.parent.companyCodes.length > 0 ||
                        ctx.parent.markets.length > 0 ||
                        ctx.parent.zones.length > 0
                      );
                    }
                  ),
                companyCodes: yup
                  .array()
                  .test(
                    "companyCodes",
                    "At least one location is required",
                    (value, ctx) => {
                      return (
                        ctx.parent.companyCodes.length > 0 ||
                        ctx.parent.markets.length > 0 ||
                        ctx.parent.zones.length > 0
                      );
                    }
                  ),
                incoTerms: yup.string().required("This field is required."),
                spendCat1Id: yup.string().required("This field is required."),
                businessUnits: yup.array().notRequired(),
                yearlySpendCurrencyCode: yup.string().notRequired(),
                validFrom: yup.date().nullable().notRequired(),
                validTo: yup.date().required("This field is required."),
                noteRequester: yup.string().notRequired(),
                noteLocalProcurement: yup.string().notRequired(),
                relatedToEasyBuy: yup.boolean().notRequired(),
                scope: yup.number().required("This field is required."),
              },
              [
                ["zones", "markets"],
                ["zones", "companyCodes"],
                ["markets", "companyCodes"],
              ]
            ),
          })}
        >
          <CreateStepOneContainer />
        </FormikStep>
        <FormikStep
          title="Materials"
          validationSchema={yup.object().shape({
            materials: yup
              .array()
              .of(PromoItemRowSchema)
              .min(1, "At least one material is required.")
              .test(
                "minimumOrderQuantity",
                "Quality Approver is required",
                (value, context) => {
                  const anyQualityRequired =
                    context.parent.vendor.parentId &&
                    context.parent.materials.some(
                      (m) =>
                        m.promoCategoryQualityRequired == true ||
                        m.promoCategoryQualityRequired == "true"
                    );
                  return anyQualityRequired
                    ? context.parent.vendor.qualityApprover != null
                    : true;
                }
              ),
          })}
        >
          <CreateStepTwoContainer
            defaultSpendCategories={defaultSpendCategories}
          />
        </FormikStep>
      </FormikStepperProvider>
    </>
  );
};

export default CreatePromoItemContainer;
