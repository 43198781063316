import React, { useEffect, useState } from "react";
import SplashScreen from "modules/UI/components/SplashScreen.component";
import StaticDataService from "../service/staticData.service";
import { INCOTERMS } from "service/generic.constants";
import { StaticData } from "../service/staticData.types";

export const StaticDataContext = React.createContext<StaticData>({
  spendCategories: [],
  spendCategoriesHashMap: {},
  businessUnits: [],
  languages: [],
  currencies: [],
  incoterms: [],
  incotermsHashMap: {}
});

export const StaticDataProvider = ({ children }) => {
  const [data, setData] = useState<StaticData>({
    spendCategories: [],
    spendCategoriesHashMap: {},
    businessUnits: [],
    languages: [],
    currencies: [],
    incotermsHashMap: INCOTERMS,
    incoterms: Object.keys(INCOTERMS).map((k) => ({
      id: k,
      name: INCOTERMS[k],
    }))
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const init = async () => {
    try {
      const spendCategories = await StaticDataService.fetchCategoryTree();
      const spendCategoriesHashMap = spendCategories.reduce((acc, curr) => {
        acc[curr.id] = curr;
        if (curr.spendCategories) {
          curr.spendCategories.forEach((spendCatL2) => {
            acc[spendCatL2.id] = spendCatL2;
            if (spendCatL2.spendCategories) {
              spendCatL2.spendCategories.forEach((spendCatL3) => {
                acc[spendCatL3.id] = spendCatL3;
              });
              // delete spendCatL2.spendCategories;
            }
          });
          // delete curr.spendCategories;
        }

        return acc;
      }, {});
      const businessUnits = await StaticDataService.fetchBusinessUnits();
      const languages = await StaticDataService.fetchLanguages();
      const currencies = await StaticDataService.fetchCurrencies();
      setData({
        ...data,
        currencies,
        spendCategories,
        spendCategoriesHashMap,
        businessUnits,
        languages,
      });
    } catch (e) {
      console.log({ error: e });
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <StaticDataContext.Provider value={data}>
      {" "}
      <SplashScreen
        message="Loading Resources..."
        status={isLoading ? "loading" : error ? "error" : "idle"}
      />
      {!isLoading && !error && children}
      {/* <pre><code>{JSON.stringify(ctx.spendCategoriesHashMap, null, 3)}</code></pre> */}
    </StaticDataContext.Provider>
  );
};
