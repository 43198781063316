import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Badge,
  ButtonBase,
  useTheme,
  TextField,
  Paper,
  InputAdornment,
} from "@mui/material";
// import SwipeableViews from "react-swipeable-views";
import StaticResources from "service/dummyData/resources";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import PromoItemsService from "modules/PromoItems/services/promoItems.service";
import { capitalizeFirstLetter } from "utils/utils";
import PromoItemCard from "modules/PromoItems/components/PromoItemCard.component";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  Search,
} from "@mui/icons-material";
import LogoCarousel from "../components/LogoCarousel.component";
import { environment } from "env";
import AdminService from "modules/Admin/service/admin.service";

export const Slide = (props: {
  title?: string;
  subtitle?: string;
  description?: string;
  isActive?: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  onCtaClick?: () => void;
  ctaText?: string;
  backgroundImage?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "flex-end",
        padding: "100px 0",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom center",
        // backgroundAttachment: "fixed",
        transition: ".2s ease-in",
        opacity: props.isActive ? 1 : 0,
        pointerEvents: props.isActive ? "auto" : "none",
        backgroundImage: !props.backgroundImage
          ? null
          : `linear-gradient(0deg,rgba(0,0,0,.2) 100px, transparent), url(${props.backgroundImage})`,
      }}
    >
      {!!props.onPrevious && (
        <Button
          sx={{
            color: "white",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: 10,
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
          size="large"
          onClick={props.onPrevious}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight sx={{ fontSize: 64 }} />
          ) : (
            <KeyboardArrowLeft sx={{ fontSize: 64 }} />
          )}
        </Button>
      )}
      {!!props.onNext && (
        <Button
          sx={{
            color: "white",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            zIndex: 10,
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
          size="large"
          onClick={props.onNext}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft sx={{ fontSize: 64 }} />
          ) : (
            <KeyboardArrowRight sx={{ fontSize: 64 }} />
          )}
        </Button>
      )}
      <Container maxWidth="xl">
        <Box
          sx={{
            width: 460,
            maxWidth: "100%",
            transition: ".3s .2s ease-out",
            transform: props.isActive ? "none" : "translateY(100px)",
            opacity: props.isActive ? 1 : 0,
            pointerEvents: props.isActive ? "auto" : "none",
          }}
        >
          {props.subtitle && (
            <Typography
              variant="body1"
              sx={{ mb: 4, textShadow: "0 5px 20px black" }}
              color="white"
            >
              {props.subtitle}
            </Typography>
          )}
          {props.title && (
            <Typography
              variant="h3"
              color="white"
              sx={{ textShadow: "0 10px 50px black" }}
            >
              {props.title}
            </Typography>
          )}
          {props.description && (
            <Typography
              variant="subtitle1"
              sx={{ mb: 4, textShadow: "0 5px 20px black" }}
              color="white"
            >
              {props.description}
            </Typography>
          )}
          {props.onCtaClick && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => props.onCtaClick()}
            >
              {props.ctaText || "Learn More"}
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export const LandingView = () => {
  const theme = useTheme();
  const [activeSlide, setActiveSlide] = React.useState(0);
  const navigate = useNavigate();
  const { data: promoCategories, isLoading: isLoadingCategories } = useQuery({
    queryKey: ["promo-categories/get-all"],
    queryFn: async () => {
      const response = await AdminService.getAllPromoCategories();
      return response;
    },
    initialData: [],
  });
  const {
    data: backendData,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["materials-list"],
    enabled: true,
    retry: 1,
    queryFn: async () => {
      const response = await PromoItemsService.getMaterials({
        searchTerm: "",
        take: 4,
        skip: 0,
        spendCatL1s: [],
        spendCatL2s: [],
        spendCatL3s: [],
        companies: [],
        markets: [],
        statuses: [],
        complianceStatuses: [],
        suppliers: [],
        materials: [],
        businessUnits: [],
        strategicBuyers: [],
        supplierDiversity: [],
        wayOfBuying: [],
        time: "active",
        pslStatuses: [{ id: 1, title: "Approved" }],
        scope: [{ id: "At Market", title: "At Market" }],
        exactId: null,
        taskKind: null,
        createdByUserId: null,
        fromSpend: "",
        toSpend: "",
        childrenOf: "",
        currencySpend: "",
        plants: [],
        orderBy: {
          field: "ValidFrom",
          direction: "asc",
        },
        pslCreationAndValidityDate: null,
        zones: [],
        pslIds: [],
        pslSearch: null,
        materialSearch: null,
        showObsolete: false,
        FavoriteSwitchOn: false,
        supplierDescription: [],
      });
      return response;
    },
  });

  const data = useMemo(() => {
    return backendData?.data || [];
  }, [backendData]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/browse/?searchTerm=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box sx={{ m: -3 }}>
      {/* <Box sx={{ position: "relative", height: "80vh" }}>
        <Slide
          isActive={activeSlide === 0}
          backgroundImage="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
          onNext={() => {
            setActiveSlide(1);
          }}
        />
        <Slide
          isActive={activeSlide === 1}
          backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de70ae11079143.560f10407d139.jpg"
          onPrevious={() => {
            setActiveSlide(0);
          }}
        />
      </Box> */}

      <Box
        sx={{
          height: "80vh",
          background:
            "linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(90deg, rgb(195, 195, 195),rgb(228, 228, 228)); background-blend-mode:overlay,overlay,overlay,normal",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid container spacing="4" justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography variant="h2" textAlign={"center"} sx={{ mb: "1em" }}>
                Welcome to Nestlé Supplier Catalogs
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ width: "100%" }}>
                <TextField
                  autoFocus
                  size="medium"
                  type="search"
                  fullWidth
                  placeholder="Search for materials"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearch} edge="end">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ my: 3, fontSize: "2rem" }}
              >
                or
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  window.open(`${environment.DESCRIBE_YOUR_NEED}`, "_blank");
                }}
              >
                Describe Your Need
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="stretch"
          sx={{ mb: 10 }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center" sx={{ my: 5 }}>
              Sub Categories
            </Typography>
          </Grid>
          {promoCategories
            .filter((c) => !c.isDeleted)
            .map((category) => (
              <Grid
                item
                xs={4}
                md={2}
                xl={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  display: "flex",
                  "& button": {
                    transition: ".1s ease-in-out",
                    transform: "none",
                    // color: category.color,
                    // filter: `drop-shadow(0 0 5px ${theme.palette.text.primary})`,
                  },
                  "&:hover button": {
                    transform: "scale(1.5)",
                    // filter: `drop-shadow(0 15px 5px ${theme.palette.text.primary})`,
                  },
                }}
              >
                <Tooltip title={category.name}>
                  <ButtonBase
                    sx={{ minWidth: "100%", minHeight: "100%" }}
                    onClick={() => {
                      navigate(`/browse/?catId=${category.id}`);
                    }}
                  >
                    {!category?.material ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: category.icon }}
                      />
                    ) : (
                      <Badge
                        badgeContent={category?.material}
                        color="primary"
                        variant="standard"
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: category?.color,
                            color: "#333",
                            whiteSpace: "nowrap",
                            top: "100%",
                            opacity: 0.8,
                          },
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: category.icon }}
                        />
                      </Badge>
                    )}
                  </ButtonBase>
                </Tooltip>
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={2} alignItems={"stretch"} sx={{ mb: 10 }}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center" sx={{ my: 5 }}>
              Latest Materials
            </Typography>
          </Grid>
          {data?.map((material, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={material.pslId + index}
            >
              <PromoItemCard promoItem={material} onRefetch={() => refetch()} />
            </Grid>
          ))}
          <Grid item xs={12} justifyContent="center" sx={{ display: "flex" }}>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              onClick={() => navigate("/browse")}
            >
              Browse All Materials
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems={"stretch"} sx={{ mb: 10 }}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center" sx={{ my: 5 }}>
              Marketing Agencies
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LogoCarousel />
          </Grid>
          {/* <Grid item xs={12} justifyContent="center" sx={{ display: "flex" }}>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              onClick={() => navigate("/browse")}
            >
              Explore All Agencies
            </Button>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingView;
