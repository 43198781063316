import {
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

export interface MediaFile {
    fileId: number
    url: string
    mimeType: string
    originalFileName: string
    persistedFileNameInAzureStorage: string
    uploadedOn: string
    uploadedBy: string
    promoCategoryDocumentId: number
    isQuality: boolean
}

interface AttachmentsProps {
    mediaFiles: MediaFile[]
    onDelete: (fileId: number) => void
    deleting: boolean
}
export function AttachmentsTable({mediaFiles, onDelete, deleting}: AttachmentsProps) {
    const [deleteId, setDeleteId] = React.useState(0);
    console.log(mediaFiles, "mediaFiles")
    if (mediaFiles == undefined || mediaFiles.length == 0) {
        return <></>
    }
    return (
        <TableContainer component={Paper}>
            <Table aria-label="document table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                        <TableCell>File Name</TableCell>
                        <TableCell>Document Type</TableCell>
                        <TableCell align="center">Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mediaFiles.map((doc) => (
                        <TableRow key={doc.originalFileName} >
                            <TableCell component="th" scope="row">
                                {doc.originalFileName}
                            </TableCell>
                            <TableCell>{doc.mimeType}</TableCell>
                            <TableCell align="center">
                                <IconButton aria-label="delete" onClick={() => {
                                    setDeleteId(doc.fileId);
                                    onDelete(doc.fileId);
                                }} color="error" size="small"
                                            disabled={deleting && deleteId == doc.fileId}>
                                    {deleting && deleteId == doc.fileId ? <CircularProgress size={18}/> : <DeleteIcon />}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
