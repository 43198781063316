import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { FieldArray, Formik, FormikProps } from "formik";
import PromoCategorySelect from "modules/Forms/components/promoCategorySelect.component";
import {
  CustomCurrencySelect,
  CustomLanguageSelect,
  MaterialSelector,
} from "modules/PromoItems/components/PromoItemRow.component";
import React, { useState } from "react";
import { CampaignMaterialType } from "../service/campaigns.types";
import { CampaignMaterialSchema } from "../service/campaigns.schemas";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";
import { BarcodeButton } from "modules/UI/components/BarcodeButton.component";
import { MuiChipsInput } from "lib/mui-chips-input";
import { MediaManager } from "modules/PromoItems/components/MediaManager.component";
import { PromoItemRowSchema } from "modules/PromoItems/services/promoItems.schemas";
import { useMutation, useQuery } from "@tanstack/react-query";
import CampaignsService from "../service/campaigns.service";
import { toast } from "react-toastify";
import { Clear, CloudUploadOutlined, HelpOutline } from "@mui/icons-material";
import Editor from "modules/UI/components/Editor.component";
import AdminService from "modules/Admin/service/admin.service";
import { useActiveUser } from "modules/User/activeUser";

export const CampaignMaterialForm = (props: {
  initialValues: CampaignMaterialType;
  onClose: () => void;
}) => {
  const currentUser = useActiveUser();
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = useState<string | false>(
    props.initialValues?.readOnly ? "3" : false
  );
  const { data: promoCategories, isLoading: isLoadingCategories } = useQuery({
    queryKey: ["promo-categories/get-all-hash"],
    queryFn: async () => {
      const response: any[] = await AdminService.getAllPromoCategories();
      return response.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    initialData: {},
  });
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<string[]>(
    props.initialValues ? [props.initialValues.unitOfMeasure] : []
  );

  const { mutateAsync } = useMutation<
    any,
    any,
    Parameters<typeof CampaignsService.updateCampaignMaterial>[0]
  >({
    mutationKey: ["createCampaignMaterial"],
    mutationFn: (values) => {
      return !values.id
        ? CampaignsService.createCampaignMaterial(values)
        : CampaignsService.updateCampaignMaterial(values);
    },
    onSuccess: () => {
      props.onClose();
      toast.success("Campaign Material created successfully");
    },
  });

  const { mutateAsync: uploadCampaignMaterialMedia } = useMutation<
    any,
    any,
    Parameters<typeof CampaignsService.uploadCampaignMaterialMedia>[0]
  >({
    mutationKey: ["uploadCampaignMaterialMedia"],
    mutationFn: async (data) =>
      await CampaignsService.uploadCampaignMaterialMedia(data),
  });

  const { mutateAsync: deleteCampaignMaterialMedia } = useMutation<
    any,
    any,
    any
  >({
    mutationKey: ["deleteCampaignMaterialMedia"],
    mutationFn: async (fileId) => {
      await CampaignsService.deleteCampaignMaterialMedia({ fileId });
      return fileId;
    },
  });
  return !props.initialValues ? (
    <>loading...</>
  ) : (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      validationSchema={CampaignMaterialSchema}
      onSubmit={(values) => {
        setLoading(true);
        const payload = {
          ...values,
          orderWindowId: values.orderWindowId,
          minimumOrderQuantity: values.minimumOrderQuantity,
          minimumPurchaseQuantity: values.minimumPurchaseQuantity,
          priceRanges: values.priceRanges,
          spendCat1Id: "1476",
          spendCat1DisplayValue: "39 Marketing",
          spendCat1Code: "S39",
          spendCat2Id: "1484",
          spendCat2DisplayValue: "39 Consumer Activation",
          spendCat2Code: "S390036",
          spendCat3Id: "1519",
          spendCat3Code: "S390041",
          spendCat3DisplayValue: "39 Printed Material and Premiums",
          // Add other properties as needed
        };
        mutateAsync(payload).finally(() => {
          setLoading(false);
        });
      }}
    >
      {({ values, setFieldValue, getFieldMeta, submitForm, errors }) => (
        <>
          <Accordion
            expanded={expanded === "1"}
            onChange={(e, isExpanded) => setExpanded(isExpanded ? "1" : false)}
          >
            <AccordionSummary
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Product Photos
            </AccordionSummary>
            <AccordionDetails>
              <MediaManager
                images={
                  values?.mediaFiles?.filter(
                    (file) => file.mimeType.indexOf("image/") > -1
                  ) || []
                }

                readOnly={props.initialValues?.readOnly}
                onUpload={(files) => {
                  uploadCampaignMaterialMedia({
                    file: files[0],
                    campaignMaterialId: values.id,
                  })
                    .then((response) => {
                      setFieldValue(
                        "mediaFiles",
                        values.mediaFiles.concat(response)
                      );
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
                onDelete={(mediaId) => {
                  deleteCampaignMaterialMedia(mediaId).then((deletedFileId) => {
                    setFieldValue(
                      "mediaFiles",
                      values.mediaFiles.filter(
                        (f) => f.fileId !== deletedFileId
                      )
                    );
                  });
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "2"}
            onChange={(e, isExpanded) => setExpanded(isExpanded ? "2" : false)}
          >
            <AccordionSummary
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Product Information
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <PromoCategorySelect
                  disabled={props.initialValues?.readOnly}
                  error={getFieldMeta(`promoCategoryId`).error}
                  value={values.promoCategoryId as string}
                  onChange={(e) => {
                    setFieldValue(`promoCategoryId`, e.id);
                  }}
                />
                <FormControl
                  fullWidth
                  size="small"
                  // disabled={!!isLoadingAmdrMaterials}
                  disabled={props.initialValues?.readOnly}
                  error={!!getFieldMeta("vendor.materialNumber").error}
                >
                  <InputLabel
                    htmlFor="select-material-number"
                    sx={{ position: "static", mb: -2, zIndex: 90 }}
                    shrink
                  >
                    {`Material number
                    ${
                      !(
                        PromoItemRowSchema.describe().fields[
                          "materialNumber"
                        ] as any
                      )?.optional
                        ? "*"
                        : ""
                    }`}
                  </InputLabel>
                  <Box
                    display="flex"
                    gap={2}
                    alignItems={"flex-start"}
                    sx={{ width: "100%" }}
                  >
                    <MaterialSelector
                      sx={{ flexGrow: 1 }}
                      // disabled={false} //ToDo: check promoitem row for this condition
                      disabled={props.initialValues?.readOnly}
                      spendCat3Code={"S390041"}
                      companyCodes={""}
                      // l3={l3}
                      initialValue={
                        values.materialName
                          ? {
                              label: values.materialName,
                              value: values.materialNumber,
                            }
                          : null
                      }
                      applyChange={(material) => {
                        if (!material) return;
                        console.log({ material, values });
                        setFieldValue(`materialNumber`, material?.value || "");
                        setFieldValue(`materialName`, material?.label || "");
                        setUnitsOfMeasure(material?.uoM?.split(",") || []);
                      }}
                    />
                    <Tooltip
                      arrow
                      title={
                        "Auto-populated with the ZSIM number corresponding to the L3 category. It can be changed with a coded material (NLAG, ERSA, UNBW, etc.) if necessary.The replication in the WebApp of newly created materials in SAP occurs once a day overnight"
                      }
                    >
                      <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                    </Tooltip>
                  </Box>
                  {!!getFieldMeta(`materialNumber`).error && (
                    <FormHelperText error>
                      {getFieldMeta(`materialNumber`).error}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box display="flex" gap={2} alignItems={"center"}>
                  <TextFieldWrapper
                    fullWidth
                    size="small"
                    name="shortDescription"
                    disabled={props.initialValues?.readOnly}
                    label={`Short Description ${
                      !(
                        CampaignMaterialSchema.describe().fields[
                          "shortDescription"
                        ] as any
                      )?.optional
                        ? "*"
                        : ""
                    }`}
                  />
                  <Tooltip
                    arrow
                    title={
                      "Brief description of the material. This will be displayed in the Purchase Orders"
                    }
                  >
                    <HelpOutline />
                  </Tooltip>
                </Box>
                <Box display="flex" gap={2} alignItems={"center"}>
                  <Editor
                    // ref={quillRef}

                    readOnly={props.initialValues?.readOnly}
                    defaultValue={
                      values.longDescription || "<em>Long Description</em>"
                    }
                    onChange={(value) => {
                      setFieldValue("longDescription", value);
                    }}
                  />
                  <Tooltip
                    arrow
                    title={
                      "Detailed description of the material. This will NOT be displayed in the Purchase Orders"
                    }
                  >
                    <HelpOutline />
                  </Tooltip>
                </Box>
                <Box display="flex" gap={2} alignItems={"flex-start"}>
                  <TextFieldWrapper
                    disabled={props.initialValues?.readOnly}
                    name={`itemDescription`}
                    label={`Scope of Application
                      ${
                        !(
                          CampaignMaterialSchema.describe().fields[
                            "itemDescription"
                          ] as any
                        )?.optional
                          ? "*"
                          : ""
                      }`}
                    size="small"
                    fullWidth
                  />
                  <Tooltip
                    arrow
                    title={
                      "This field helps to further clarify the scope of materials for which you defined the supplier as a Mandatory, Sole Source or Validated"
                    }
                  >
                    <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                  </Tooltip>
                </Box>
                <Box display="flex" gap={2} alignItems={"flex-start"}>
                  <TextFieldWrapper
                    disabled={props.initialValues?.readOnly}
                    name={`supplierPartId`}
                    label={`Supplier Part ID
                      ${
                        !(
                          CampaignMaterialSchema.describe().fields[
                            "supplierPartId"
                          ] as any
                        )?.optional
                          ? "*"
                          : ""
                      }`}
                    size="small"
                    fullWidth
                    slotProps={{
                      input: {
                        endAdornment: !values.supplierPartId ? null : (
                          <BarcodeButton value={values.supplierPartId || ""} />
                        ),
                      },
                    }}
                  />
                  <Tooltip
                    arrow
                    title={"Material reference number of the supplier"}
                  >
                    <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                  </Tooltip>
                </Box>
                <Box display="flex" gap={2} alignItems={"flex-start"}>
                  <TextFieldWrapper
                    disabled={props.initialValues?.readOnly}
                    name={`manufacturerName`}
                    label={`Manufacturer Name
                      ${
                        !(
                          CampaignMaterialSchema.describe().fields[
                            "manufacturerName"
                          ] as any
                        )?.optional
                          ? "*"
                          : ""
                      }`}
                    size="small"
                    fullWidth
                  />
                  <Tooltip
                    arrow
                    title={
                      "Optionally, for reporting and traceability reasons, indicate the name of the manufacturer"
                    }
                  >
                    <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                  </Tooltip>
                </Box>
                <Box display="flex" gap={2} alignItems={"flex-start"}>
                  <TextFieldWrapper
                    disabled={props.initialValues?.readOnly}
                    name={`manufacturerPartNumber`}
                    label={`Manufacturer Part Number
                      ${
                        !(
                          CampaignMaterialSchema.describe().fields[
                            "manufacturerPartNumber"
                          ] as any
                        )?.optional
                          ? "*"
                          : ""
                      }`}
                    size="small"
                    fullWidth
                    slotProps={{
                      input: {
                        endAdornment: !values.manufacturerPartNumber ? null : (
                          <BarcodeButton
                            value={values.manufacturerPartNumber || ""}
                          />
                        ),
                      },
                    }}
                  />
                  <Tooltip
                    arrow
                    title={"Material reference number of the manufacturer"}
                  >
                    <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                  </Tooltip>
                </Box>
                <Box
                  display="flex"
                  gap={2}
                  alignItems={"flex-start"}
                  sx={{ width: "100%" }}
                >
                  <TextFieldWrapper
                    disabled={props.initialValues?.readOnly}
                    name={`supplierLeadTime`}
                    label={`Supplier Lead Time
                      ${
                        !(
                          CampaignMaterialSchema.describe().fields[
                            "supplierLeadTime"
                          ] as any
                        )?.optional
                          ? "*"
                          : ""
                      }`}
                    size="small"
                    fullWidth
                  />
                  <Tooltip
                    arrow
                    placement={"right"}
                    title={"Lead times for the supplier to deliver"}
                  >
                    <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                  </Tooltip>
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "3"}
            onChange={(e, isExpanded) => setExpanded(isExpanded ? "3" : false)}
          >
            <AccordionSummary
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Pricing Information
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <Box display="flex" gap={2} alignItems={"flex-start"}>
                  <CustomCurrencySelect
                    disabled={props.initialValues?.readOnly}
                    required={
                      !(
                        CampaignMaterialSchema.describe().fields[
                          "currencyCode"
                        ] as any
                      )?.optional
                    }
                    value={values.currencyCode}
                    onChange={(newValue) =>
                      setFieldValue(`currencyCode`, newValue)
                    }
                  />
                  <Tooltip
                    arrow
                    title={
                      "Indicate the currency of the price you negotiated (mandatory only if a unit price is given)"
                    }
                  >
                    <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                  </Tooltip>
                </Box>
                <Tooltip
                  arrow
                  placement={"right"}
                  title={
                    "Auto-populated to EA for “Each”. Editable if required. Unit of Measure of the material"
                  }
                >
                  <FormControl
                    fullWidth
                    size="small"
                    disabled={props.initialValues?.readOnly}
                  >
                    <InputLabel
                      htmlFor="unit-of-measure-select"
                      id="unit-of-measure-label"
                    >
                      {`Unit of Measure
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "unitOfMeasure"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                    </InputLabel>
                    <Select
                      size="small"
                      fullWidth
                      label={`Unit of Measure
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "unitOfMeasure"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                      labelId="unit-of-measure-label"
                      id="unit-of-measure-select"
                      error={!!getFieldMeta(`unitOfMeasure`).error}
                      value={values.unitOfMeasure}
                      onChange={(e) =>
                        setFieldValue(`unitOfMeasure`, e.target.value)
                      }
                    >
                      {unitsOfMeasure.map((uom) => {
                        return <MenuItem value={uom}>{uom}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Tooltip>
                <TextFieldWrapper
                  disabled={props.initialValues?.readOnly}
                  required={true}
                  type={"number"}
                  name={`minimumOrderQuantity`}
                  label={`Minimum Order Quantity (MOQ)`}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setFieldValue(`priceRanges[0].threshold`, e.target.value);
                  }}
                />
                <TextFieldWrapper
                  disabled={props.initialValues?.readOnly}
                  name={`minimumPurchaseQuantity`}
                  required={true}
                  type={"number"}
                  label={`Minimum Pack Quantity`}
                  size="small"
                  fullWidth
                  slotProps={{
                    input: {
                      endAdornment: <>{values.unitOfMeasure}</>,
                    },
                  }}
                />

                <summary>Price Ranges</summary>
                <Table size="small" padding={"normal"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>PricePer</TableCell>
                      {!props.initialValues?.readOnly && (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="priceRanges">
                      {(arrayHelpers) => {
                        return (
                          <>
                            {values.priceRanges.map((priceRange, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ width: "35%" }}>
                                  <TextFieldWrapper
                                    disabled={
                                      index === 0 ||
                                      props.initialValues?.readOnly
                                    }
                                    name={`priceRanges[${index}].threshold`}
                                    size="small"
                                    fullWidth
                                    variant="standard"
                                    slotProps={{
                                      input: {
                                        endAdornment: (
                                          <>{values.unitOfMeasure}</>
                                        ),
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{ width: "35%", padding: "6px" }}
                                >
                                  <TextFieldWrapper
                                    disabled={props.initialValues?.readOnly}
                                    name={`priceRanges[${index}].unitPrice`}
                                    size="small"
                                    fullWidth
                                    variant="standard"
                                    slotProps={{
                                      input: {
                                        endAdornment: (
                                          <>
                                            {values.currencyCode}/
                                            {values.unitOfMeasure}
                                          </>
                                        ),
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ width: "20%" }}>
                                  <TextFieldWrapper
                                    disabled={props.initialValues?.readOnly}
                                    name={`priceRanges[${index}].pricePer`}
                                    size="small"
                                    fullWidth
                                    variant="standard"
                                  />
                                </TableCell>
                                {!props.initialValues?.readOnly && (
                                  <TableCell size={"small"} padding={"none"}>
                                    {index > 0 && (
                                      <Button
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <Clear />
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                            {!props.initialValues?.readOnly && (
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Button
                                    fullWidth
                                    onClick={() =>
                                      arrayHelpers.push({
                                        threshold: null,
                                        unitPrice: null,
                                        pricePer: 1,
                                      })
                                    }
                                  >
                                    + Add Threshold
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        );
                      }}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {promoCategories[values.promoCategoryId]?.documents?.length > 0 && (
            <Accordion
              expanded={expanded === "4"}
              onChange={(e, isExpanded) =>
                setExpanded(isExpanded ? "4" : false)
              }
            >
              <AccordionSummary
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Quality Attachments
              </AccordionSummary>
              <AccordionDetails>
                {promoCategories[values.promoCategoryId]?.documents?.map(
                  (attachment) => {
                    return (
                      <Box
                        my={2}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        {attachment.name}{" "}
                        <Button
                          component="label"
                          size="small"
                          variant="contained"
                          color="primary"
                          startIcon={<CloudUploadOutlined />}
                        >
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              uploadCampaignMaterialMedia({
                                documentId: attachment.id,
                                campaignMaterialId: values.id,
                                file: e.target.files[0],
                              });
                            }}
                          />
                          Upload
                        </Button>
                      </Box>
                    );
                  }
                )}
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion
            expanded={expanded === "5"}
            onChange={(e, isExpanded) => setExpanded(isExpanded ? "5" : false)}
          >
            <AccordionSummary
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Generic Information
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <Tooltip
                  arrow
                  title={
                    "Add as many keywords that you feel are relevant to help the requesters/spenders in finding relevant content. Press <ENTER> after each key word to record them"
                  }
                >
                  <MuiChipsInput
                    disabled={props.initialValues?.readOnly}
                    error={!!getFieldMeta(`keyWords`).error}
                    helperText={getFieldMeta(`keyWords`).error}
                    value={values.keyWords}
                    onChange={(newValue) => setFieldValue(`keyWords`, newValue)}
                    size="small"
                    fullWidth
                    label={`Keywords *`}
                  />
                </Tooltip>
                <Tooltip
                  arrow
                  title={
                    "Auto-populated with “English”. It can be changed to another language in which you maintain the material"
                  }
                >
                  <CustomLanguageSelect
                    disabled={props.initialValues?.readOnly}
                    required={
                      !(
                        CampaignMaterialSchema.describe().fields[
                          "languageId"
                        ] as any
                      )?.optional
                    }
                    error={getFieldMeta(`languageId`).error}
                    value={values.languageId}
                    onChange={(newValue) =>
                      setFieldValue(`languageId`, newValue)
                    }
                  />
                </Tooltip>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {[
            "ivan.ivanov@nestle.com",
            "oriol.saludes@nestle.com",
            "elitsa.evtimova@nestle.com",
            "diptaman.saha@nestle.com",
          ].indexOf(currentUser.email.toLowerCase()) > -1 && (
            <>
              <Accordion>
                <AccordionSummary>Values (devs only)</AccordionSummary>
                <AccordionDetails>
                  <pre>
                    <code>{JSON.stringify(values, null, 3)}</code>
                  </pre>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary>Errors (devs only)</AccordionSummary>
                <AccordionDetails>
                  <pre>
                    <code>{JSON.stringify(errors, null, 3)}</code>
                  </pre>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {!props.initialValues?.readOnly && (
            <Box py={2} textAlign={"right"}>
              <Button
                sx={{ ml: "auto" }}
                size="large"
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading || JSON.stringify(errors, null, 3).length > 2}
                onClick={() => submitForm()}
              >
                {loading ? "Submitting" : "Submit"}
              </Button>
            </Box>
          )}
        </>
      )}
    </Formik>
  );
};

export default CampaignMaterialForm;
