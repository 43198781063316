export const INCOTERMS = {
  EXW: "Ex Works",
  /*
    Seller's Obligations: Make goods available at their premises.
    Buyer's Obligations: Arrange and pay for all transport, export/import customs clearance, and insurance.
    Risk Transfer: When goods are made available at the seller's premises.
  */
  FCA: "Free Carrier",
  /*
    Seller's Obligations: Deliver goods to a specified carrier at a named place.
    Buyer's Obligations: Arrange and pay for all subsequent transport, export/import customs clearance, and insurance.
    Risk Transfer: When goods are handed over to the carrier.
  */
  FAS: "Free Alongside Ship",
  /*
    Seller's Obligations: Deliver goods alongside the vessel at the named port of shipment.
    Buyer's Obligations: Arrange and pay for loading, transport to the port of destination, insurance, and import customs clearance.
    Risk Transfer: When goods are placed alongside the vessel.
  */
  FOB: "Free On Board Vessel",
  /*
    Seller's Obligations: Deliver goods on board the vessel at the named port of shipment.
    Buyer's Obligations: Arrange and pay for transport to the port of destination, insurance, and import customs clearance.
    Risk Transfer: When goods are passed the ship's rail.
  */
  CFR: "Cost and Freight",
  /*
    Seller's Obligations: Pay for transport to the named port of destination.
    Buyer's Obligations: Arrange and pay for insurance and import customs clearance.
    Risk Transfer: When goods are passed the ship's rail.
  */
  CIF: "Cost, insurance and freight",
  /*
    Seller's Obligations: Pay for transport to the named port of destination and arrange/pay for basic insurance.
    Buyer's Obligations: Arrange and pay for import customs clearance.
    Risk Transfer: When goods are passed the ship's rail.
  */
  CPT: "Carriage Paid to",
  /*
    Seller's Obligations: Pay for transport to the named destination.
    Buyer's Obligations: Arrange and pay for insurance, import customs clearance, and any transport beyond the named destination.
    Risk Transfer: When goods are delivered to the carrier at the origin.
  */
  CIP: "Carriage and Insurance Paid to",
  /*
    Seller's Obligations: Pay for transport to the named destination.
    Buyer's Obligations: Arrange and pay for insurance, import customs clearance, and any transport beyond the named destination.
    Risk Transfer: When goods are delivered to the carrier at the origin.
  */
  DAP: "Delivered At Place",
  /*
    Seller's Obligations: Deliver goods to the named place of destination.
    Buyer's Obligations: Arrange and pay for import customs clearance.
    Risk Transfer: When goods are placed at the disposal of the buyer at the named destination.
  */
  DPU: "Delivered At Place Unloaded",
  /*
    Seller's Obligations: Deliver goods to the named place of destination and unload them.
    Buyer's Obligations: Arrange and pay for import customs clearance, and any transport beyond the named destination.
    Risk Transfer: When goods are unloaded at the named destination.
  */
  DDP: "Delivered Duty Paid",
  /*
    Seller's Obligations: Deliver goods to the named place of destination, clear customs for import, and pay all import duties and taxes.
    Buyer's Obligations: None.
    Risk Transfer: When goods are placed at the disposal of the buyer at the named destination.
  */
};
