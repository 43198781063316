import {useQuery} from "@tanstack/react-query";
import AdminService from "./admin.service";

interface Document {
    id: number;
    name: string;
}
export interface PromoCategory {
    color: string;
    documents: Document[];
    icon: string;
    id: number;
    isDeleted: boolean;
    name: string;
    promoCategoryType: number;
    qualityControl: boolean;
    requiredDocuments: any[];
}

export const useFetchPromoCategories = () :{promoCategories: PromoCategory[], isLoadingCategories: boolean} => {
    const { data: promoCategories, isLoading: isLoadingCategories } = useQuery({
        queryKey: ["promo-categories/get-all-hash"],
        queryFn: async () => {
            const response: any[] = await AdminService.getAllPromoCategories();
            return response.reduce((acc, curr) => {
                acc[curr.id] = curr;
                return acc;
            }, {});
        },
        initialData: {},
    });
    return { promoCategories: promoCategories ?? [], isLoadingCategories };
}