import {
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton, InputAdornment,
  InputBase,
  InputLabel,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { MRT_SortingState, MRT_PaginationState } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { capitalizeFirstLetter, toTitleCase } from "utils/utils";
import PromoItemsService from "../services/promoItems.service";
import MaterialsGridContainer from "./materialsGrid.container";
import PromoItemDetails from "../components/PromoItemDetails.component";
import { onChange } from "react-toastify/dist/core/store";
import StaticResources from "service/dummyData/resources";
import {
  Filter,
  FilterAlt,
  FindInPage,
  FindInPageOutlined,
  Search,
} from "@mui/icons-material";
import { PSL_SCOPE, PslStatus } from "../domains/psl.domain";
import { useParams, useSearchParams } from "react-router-dom";
import Drawer from "modules/UI/components/Drawer.component";
import { filter } from "ramda";
import {
  PslFiltersContainer,
  defaultFiltersState,
} from "modules/Filters/containers/PslFilters.container";
import { render } from "@testing-library/react";
import AdminService from "modules/Admin/service/admin.service";
import { useFeatures } from "modules/FeatureManagement/service/featureManagement.hooks";
import { useActiveUser } from "modules/User/activeUser";
import { IUserScopeCompany, UserService } from "modules/User/service/user.service";

export const BrowseContainer = (props) => {
  const theme = useTheme();
  const features = useFeatures();
  const currentUser = useActiveUser();
  const { data: promoCategories, isLoading: isLoadingCategories } = useQuery({
    queryKey: ["promo-categories/get-all"],
    queryFn: async () => {
      const response = await AdminService.getAllPromoCategories();
      return response;
    },
    initialData: [],
  });
  let [searchParams, setSearchParams] = useSearchParams();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState({
    ...defaultFiltersState,
    pslStatuses: [{ id: 1, title: "Approved" }],
    scope: [{ id: "At Market", title: "At Market" }],
    catalogType: [features["campaigns-module"] ? { id: 1, title: "All Catalogues" } : { id: 2, title: "General Catalogues" }],
  });
  const [selectedCategory, setSelectedCategory] = useState<string | number>(
    searchParams.get("catId")
  );
  const [selectedPromoItem, setSelectedPromoItem] = useState(null);
  const [activeDrawer, setActiveDrawer] = useState<"media" | "details">(null);

  const [globalFilter, setGlobalFilter] = useState(
    searchParams.get("searchTerm")
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdOn", desc: true },
  ]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });
  const {
    data: backendData,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: [
      "materials-list",
      //   globalFilter, //re-fetch when global filter changes
      pagination,
      pagination.pageIndex, //re-fetch when page index changes
      pagination.pageSize, //re-fetch when page size changes
      searchParams.toString(), //re-fetch when search params change
      sorting,
      selectedCategory,
      filters,
    ],
    enabled: true,
    retry: 1,
    queryFn: async () => {
      const response = await PromoItemsService.getMaterials({
        ...filters,
        searchTerm: searchParams.get("searchTerm") || null,
        promoCategories: selectedCategory
          ? [{ id: selectedCategory, title: selectedCategory }]
          : null,
        take: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting[0]
          ? {
            field: capitalizeFirstLetter(sorting[0].id),
            direction: sorting[0].desc ? "desc" : "asc",
          }
          : null,
        time: "active",
        pslStatuses: [{ id: 1, title: "Approved" }],
        scope: [{ id: "At Market", title: "At Market" }],
      });
      setRowCount(response?.total || 0); //set the total row count for pagination
      return response;
    },
  });

  useEffect(() => {

    const getcompCodeId = async () => {

      const compCodes = await UserService.fetchUserScope(true) as IUserScopeCompany[];
      const prefferedCompCode = compCodes.filter(item => item.code === currentUser.userPreferredCompanyCode)[0];
      if (!prefferedCompCode) return; //safety check

      setFilters(prevState => {
        return {
          ...prevState,
          companies: [{
            id: prefferedCompCode?.id, title: `${prefferedCompCode.code} - ${prefferedCompCode.name}`
          }]
        }
      })
    };

    getcompCodeId();

  }, []);

  const data = useMemo(() => {
    return backendData?.data || [];
  }, [backendData]);

  const handleSearch = () => {
    setSearchParams((oldParams) => {
      let params = new URLSearchParams(oldParams);
      params.set("searchTerm", globalFilter.toString());
      return params;
    });
  }

  return (
    <>
      <PslFiltersContainer
        open={filtersOpen}
        initialData={filters}
        onClose={() => setFiltersOpen(false)}
        onApply={(filters) => {
          setFilters(filters);
          setFiltersOpen(false);
        }}
      />
      <Card elevation={0} sx={{ p: 3, m: -3, mb: 3 }}>
        <Container maxWidth="xl">
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch} edge="end">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size="small"
                label="Search"
                placeholder="What are you looking for?"
                variant="outlined"
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                  if (e.target.value === "") {
                    setSearchParams((oldParams) => {
                      let params = new URLSearchParams(oldParams);
                      params.delete("searchTerm");
                      return params;
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                sx={{ flex: "1 1 250px" }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <FormControl sx={{ width: 250 }} size="small">
                <InputLabel htmlFor="grouped-select">Sub Category</InputLabel>
                <Select
                  defaultValue=""
                  id="grouped-select"
                  label="Sub Category"
                  value={selectedCategory || ""}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    setSearchParams((oldParams) => {
                      let params = new URLSearchParams(oldParams);
                      params.set("catId", e.target.value.toString());
                      return params;
                    });
                  }}
                  renderValue={(value) => (
                    <>
                      {toTitleCase(
                        promoCategories.find((c) => c.id === value)?.name || ""
                      )}
                    </>
                  )}
                >
                  <MenuItem value="">
                    <em>All Categories</em>
                  </MenuItem>
                  <ListSubheader>Type A</ListSubheader>
                  {promoCategories
                    .filter((c) => c.promoCategoryType == 0 && !c.isDeleted)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((category) => (
                      <MenuItem value={category.id}>
                        <ListItemIcon
                          sx={{
                            width: "2.5em",
                            height: "2.5em",
                            textAlign: "center",
                            p: 1,
                            "& svg": { maxWidth: "100%", maxHeight: "100%" },
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: category.icon }}
                          />
                        </ListItemIcon>
                        {toTitleCase(category.name)}
                      </MenuItem>
                    ))}
                  <ListSubheader>Type B</ListSubheader>
                  {promoCategories
                    .filter((c) => c.promoCategoryType == 1 && !c.isDeleted)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((category) => (
                      <MenuItem value={category.id}>
                        <ListItemIcon
                          sx={{
                            width: "2.5em",
                            height: "2.5em",
                            textAlign: "center",
                            p: 1,
                            "& svg": { maxWidth: "100%", maxHeight: "100%" },
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: category.icon }}
                          />
                        </ListItemIcon>
                        {toTitleCase(category.name)}
                      </MenuItem>
                    ))}
                  <ListSubheader>Type C</ListSubheader>
                  {promoCategories
                    .filter((c) => c.promoCategoryType == 2 && !c.isDeleted)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((category) => (
                      <MenuItem value={category.id}>
                        <ListItemIcon
                          sx={{
                            width: "2.5em",
                            height: "2.5em",
                            textAlign: "center",
                            p: 1,
                            "& svg": { maxWidth: "100%", maxHeight: "100%" },
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: category.icon }}
                          />
                        </ListItemIcon>
                        {toTitleCase(category.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Button
                // color="inherit"
                variant="outlined"
                onClick={() => {
                  setFiltersOpen(true);
                }}
                startIcon={<FilterAlt />}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Card>
      <Container maxWidth="xl">
        <PromoItemDetails
          open={!!selectedPromoItem && activeDrawer === "details"}
          promoItem={selectedPromoItem}
          onClose={() => {
            setSelectedPromoItem(null);
            setActiveDrawer(null);
          }}
        />
        {backendData?.total === 0 ? (
          <>
            <Typography variant="h1" textAlign={"center"}>
              <FindInPageOutlined sx={{ fontSize: "3rem" }} />
            </Typography>
            <Typography variant="h6" textAlign={"center"}>
              No results were found to match your search.
            </Typography>
            <Typography variant="body2" textAlign={"center"}>
              Try modifying your search criteria or create new material.
            </Typography>
          </>
        ) : (
          <MaterialsGridContainer
            data={data}
            onRefetch={refetch}
            count={backendData?.total ?? 0}
            page={
              !backendData ? 0 : Math.ceil(backendData.skip / backendData.take)
            }
            onPageChange={(e, value) => {
              setPagination({
                ...pagination,
                pageIndex: value,
              });
            }}
            onPreview={(promoItem) => {
              setSelectedPromoItem(promoItem);
              setActiveDrawer("details");
            }}
            onRowsPerPageChange={(e) => {
              setPagination({
                ...pagination,
                pageSize: parseInt(e.target.value, 10),
              });
            }}
            rowsPerPage={pagination.pageSize}
          />
        )}
        {/* <Grid container>
        <Grid item xs={12}>
        </Grid>
      </Grid> */}
      </Container>
    </>
  );
};

export default BrowseContainer;
