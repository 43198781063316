import {
  CloudUploadOutlined,
  Delete,
  Visibility,
} from "@mui/icons-material";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Tooltip,
  TextField, Box, Button, AccordionSummary, Accordion, AccordionDetails, CircularProgress, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
} from "@mui/material";
import Dropzone from "modules/Forms/components/dropzone.component";
import {
  AuthenticatedImageBackgroundDiv,
} from "modules/UI/components/AuthenticatedImage.component";
import Drawer from "modules/UI/components/Drawer.component";
import React, { useState } from "react";
import { FileRejection, DropEvent } from "react-dropzone";
import { createBlobService } from "utils/factories";
import {useMutation} from "@tanstack/react-query";
import PromoItemsService from "../services/promoItems.service";
import {useFetchPromoCategories} from "../../Admin/service/promoCategory.hook";
import {MediaFile, QualityAttachmentsTable } from "./QualityAttachmentsTable";

function fileToDataUrl(file: File) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}

export type MediaManagerPropsType = {
  onUpload?: (files: File[], data?:any) => void;
  onDelete?: (mediaId: any) => void;
  onUploadFromUrl?: (link: string) => void;
  images?: any[];
  error?: any;
  readOnly?: boolean;
};

export type MediaManagerDrawerPropsType = MediaManagerPropsType & {
  open?: boolean;
  onClose?: () => void;
  promoCategoryId?: number;
  materialId?: number;
  qualityDocs?: MediaFile[];
  loadingQualityDocs?: boolean;
    deletingQualityDocs?: boolean;
};

export const MediaManager = ({
  onUpload,
  onUploadFromUrl,
  onDelete,
  images,
  error,
  readOnly,
}: MediaManagerPropsType) => {
  const [url, setUrl] = useState<string>("");
  return (
    <>
      {!readOnly && (
        <>
          {!!onUploadFromUrl && (
            <TextField
              type="url"
              placeholder="https://example.com/image.jpg"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              label="Upload from URL"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onUploadFromUrl(url);
                  setUrl("");
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    disabled={
                      !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
                        url
                      )
                    }
                    onClick={() => {
                      onUploadFromUrl(url);
                      setUrl("");
                    }}
                  >
                    <CloudUploadOutlined />
                  </IconButton>
                ),
              }}
            />
          )}
          <Dropzone
            error={error}
            multiple
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
            }}
            placeholder={"Drag and drop more media files here..."}
            onDrop={async function <T extends File>(
              acceptedFiles: T[],
              fileRejections: FileRejection[],
              event: DropEvent
            ): Promise<void> {
              !!onUpload && onUpload(acceptedFiles);
            }}
          />
        </>
      )}
      <ImageList cols={3} rowHeight={164}>
        {images?.map((item) => (
          <ImageListItem key={item.fileId} sx={{ overflow: "hidden" }}>
            <AuthenticatedImageBackgroundDiv
              src={item.url}
              style={{
                width: 142,
                height: 165,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                border: "1px solid #eee",
              }}
            />
            <ImageListItemBar
              actionIcon={
                <>
                  <Tooltip title="Preview">
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      onClick={() => {
                        createBlobService(item.url, "GET")().then((blob) => {
                          window.open(URL.createObjectURL(blob), "_blank");
                        });
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  {!readOnly && (
                    <Tooltip title="Remove">
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        onClick={() => {
                          !!onDelete && onDelete(item.fileId);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export const MediaManagerDrawer = ({
  open,
  onClose,
  onUpload,
  onUploadFromUrl,
  onDelete,
  images,
  readOnly,
}: MediaManagerDrawerPropsType) => {
  return (
    <Drawer open={open} onClose={() => onClose()} title="Manage Mutimedia">
      <MediaManager
        onUpload={onUpload}
        onUploadFromUrl={onUploadFromUrl}
        onDelete={onDelete}
        images={images}
        readOnly={readOnly}
      />
    </Drawer>
  );
};
export default MediaManagerDrawer;
export const PromoMediaManagerDrawer = ({
                                     open,
                                     onClose,
                                     onUpload,
                                     onUploadFromUrl,
                                     onDelete,
                                     images,
                                     readOnly,
    promoCategoryId, materialId, qualityDocs, loadingQualityDocs, deletingQualityDocs
                                   }: MediaManagerDrawerPropsType) => {
  const [expanded, setExpanded] = useState<string | false>("1");

  const { promoCategories, isLoadingCategories } = useFetchPromoCategories();

  return (
      <Drawer open={open} onClose={() => onClose()} title="Manage Mutimedia">
        <Accordion
            expanded={expanded === "1"}
            onChange={(e, isExpanded) => setExpanded(isExpanded ? "1" : false)}
        >
          <AccordionSummary
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            Product Photos
          </AccordionSummary>
          <AccordionDetails>
            <MediaManager
                onUpload={onUpload}
                onUploadFromUrl={onUploadFromUrl}
                onDelete={onDelete}
                images={images}
                readOnly={readOnly}
            />
          </AccordionDetails>
        </Accordion>
        {promoCategories[promoCategoryId]?.documents?.length > 0 && (
            <Accordion
                expanded={expanded === "4"}
                onChange={(e, isExpanded) =>
                    setExpanded(isExpanded ? "4" : false)
                }
            >
              <AccordionSummary
                  sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Quality Attachments
              </AccordionSummary>
              <AccordionDetails>
                {promoCategories[promoCategoryId]?.documents?.map(
                    (attachment) => {
                      return (
                          <Box
                              my={2}
                              display={"flex"}
                              justifyContent={"space-between"}
                          >
                            {attachment.name}{" "}
                            <Button
                                component="label"
                                size="small"
                                variant="contained"
                                color="primary"
                                startIcon={loadingQualityDocs ? <CircularProgress size={18}/> : <CloudUploadOutlined />}
                                disabled={loadingQualityDocs}
                            >
                              <input
                                  type="file"
                                  hidden
                                  onChange={(e) => {
                                      onUpload([e.target.files[0]],{
                                          document: attachment.id,
                                          materialId: materialId,
                                          isQuality: true,
                                  })

                                  }}
                              />
                              Upload
                            </Button>
                          </Box>
                      );
                    }
                )}
                <QualityAttachmentsTable
                    mediaFiles={qualityDocs}
                    promoCategory={promoCategories[promoCategoryId]}
                    onDelete={fileId => onDelete(fileId)}
                    deleting={deletingQualityDocs}
                />
              </AccordionDetails>
            </Accordion>
        )}
      </Drawer>
  );
};


