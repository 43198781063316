import React, { useEffect, useState } from "react";
import Drawer from "modules/UI/components/Drawer.component";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { UserCategoryComponent } from "./userScopeSelector/userCategorySelector";
import { CustomFastInput } from "modules/Forms/components/form.legacy.component";
import { UserScopeComponent } from "./userScopeSelector";
import { ActiveUserContext, useActiveUser } from "../activeUser";
import PUIUser from "modules/UI/components/User.component";
import PUIAvatar from "modules/UI/components/Avatar.component";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { UserService } from "../service/user.service";
import UserRoleSwitcher from "./UserRoleSwitcher.component";
import { environment } from "env";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";

export const UserSettingsDrawer = (props: {
  open?: boolean;
  onClose?: () => void;
}) => {
  const theme = useTheme();
  const { instance } = useMsal();
  // const currentUserContext = React.useContext(ActiveUserContext);
  const resetUserScopeMutation = useMutation({
    mutationKey: ["resetUserScopeMutation"],
    mutationFn: () => UserService.resetUserAuthorizedScopeOnPreProd({}),
    onSuccess: () => {
      toast.success("User scope reset successfully");
    },
  });
  const currentUser = useActiveUser();
  const findRole = (options, role) => {
    return options.find((el) => el.value === role);
  };
  const [prpoSettings, setPrpoSettings] = useState({
    role: { options: [], selected: null },
    markets: currentUser.filters?.userScope.markets,
    companyCodes: currentUser.filters?.userScope.companies,
    spendCatL1s: currentUser.filters?.userScope.spendCatL1s,
    spendCatL2s: currentUser.filters?.userScope.spendCatL2s,
    spendCatL3s: currentUser.filters?.userScope.spendCatL3s,
    pslExpiryDays: currentUser.pslExpiryDays,
  });

  // return <></>;
  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      title="User Settings"
      headerContent={
        <Grid container spacing={4} alignItems={"center"} sx={{ py: 2 }}>
          <Grid item xs={5} sx={{ display: "flex", justifyContent: "center" }}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={currentUser.userType}
              color={
                currentUser.userType === "Member" ? "primary" : "secondary"
              }
            >
              <PUIAvatar
                size={84}
                src={currentUser.photoUrl}
                backgroundColor={
                  currentUser.userType === "Member"
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main
                }
              />
            </Badge>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6">{currentUser.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {currentUser.email}
            </Typography>
          </Grid>
        </Grid>
      }
      footerContent={
        <ButtonGroup>
          {environment.ENV !== "PromoCatalog-PP" ? null : (
            <Button
              disabled={resetUserScopeMutation.isPending}
              onClick={() => resetUserScopeMutation.mutate()}
            >
              Reset User Scope
            </Button>
          )}
          <Button
            onClick={() => instance.logoutRedirect().catch(console.error)}
          >
            Logout
          </Button>
        </ButtonGroup>
      }
    >
      {
        <Box>
          {[
            // {
            //   name: "User Type",
            //   value: currentUser.userType,
            // },
            // {
            //   name: "Name",
            //   value: currentUser.name,
            // },
            // {
            //   name: "E-mail",
            //   value: currentUser.email,
            // },
            {
              name: "Preferred Company",
              value: (
                <>
                  {currentUser.userPreferredCompanyCode} -{" "}
                  {currentUser.userPreferredCompanyName}
                </>
              ),
            },
            {
              name: "Role",
              value: (
                <>
                  <UserRoleSwitcher />
                </>
              ),
            },
            ...(currentUser.contactRoles
              ? [
                  {
                    name: "Contact Roles",
                    value: currentUser.contactRoles
                      .map((role) => role.name)
                      .join(", "),
                  },
                ]
              : []),
            {
              name: "Location",
              value: (
                <>
                  <UserScopeComponent
                    disabled={true}
                    company={`${currentUser.companyCode} - ${currentUser.company}`}
                    initialMarkets={currentUser.filters?.userScope.markets}
                    initialCompanies={currentUser.filters?.userScope.companies}
                    hasError={false}
                    onChange={(values) => {
                      // setPrpoSettings({
                      //   ...prpoSettings,
                      //   markets: values.markets,
                      //   companyCodes: values.companyCodes
                      // });
                    }}
                  />
                </>
              ),
            },
            {
              name: "Categories",
              value: (
                <>
                  <UserCategoryComponent
                    disabled={true}
                    initialSpendCatL1s={
                      currentUser.filters.userScope.spendCatL1s
                    }
                    initialSpendCatL2s={
                      currentUser.filters.userScope.spendCatL2s
                    }
                    initialSpendCatL3s={
                      currentUser.filters.userScope.spendCatL3s
                    }
                    onChange={(values) => {
                      setPrpoSettings({
                        ...prpoSettings,
                        spendCatL1s: values.spendCatL1s,
                        spendCatL2s: values.spendCatL2s,
                        spendCatL3s: values.spendCatL3s,
                      });
                    }}
                    hasError={false}
                  />
                </>
              ),
            },
            {
              name: "PSL Expiration Days",
              value: <>{prpoSettings.pslExpiryDays}</>,
            },
          ].map((field) => (
            <Grid container spacing={4} alignItems={"center"} sx={{ py: 2 }}>
              <Grid item xs={5}>
                <strong>{field.name}</strong>
              </Grid>
              <Grid item xs={7}>
                {field.value}
              </Grid>
            </Grid>
          ))}
        </Box>
      }
    </Drawer>
  );
};

export default UserSettingsDrawer;
